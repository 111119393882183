import React, { forwardRef } from 'react';

import type { ButtonProps } from '../types';

import { TextButton, Text } from './styled';

export const TextButtonUIComponent = forwardRef<HTMLButtonElement, ButtonProps>(({
  contentWidth = 'auto',
  leftIcon,
  rightIcon,
  btnColor,
  btnSize,
  fontWeight,
  children,
  ...props
}, ref) => {
  return (
    <TextButton
      ref={ref}
      type="button"
      contentWidth={contentWidth}
      btnColor={btnColor}
      btnSize={btnSize}
      fontWeight={fontWeight}
      {...props}
    >
      <div>
        {leftIcon}

        <Text
          hasLeftIcon={!!leftIcon}
          hasRightIcon={!!rightIcon}
        >
          {children}
        </Text>

        {rightIcon}
      </div>
    </TextButton>
  );
});

TextButtonUIComponent.displayName = 'TextButton';

export const ContainerTextButtonUIComponent = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  style,
  ...props
}, ref) => {
  return (
    <TextButtonUIComponent
      ref={ref}
      {...props}
      style={{ ...style, padding: '0 16px' }}
    >
      {children}
    </TextButtonUIComponent>
  );
});

ContainerTextButtonUIComponent.displayName = 'ContainerTextButton';
