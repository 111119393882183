import React, { ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';

interface PrivateRouteProps {
  children: ReactElement | ReactElement[];
}

function PrivateRoute({ children, ...rest }: MergeType<PrivateRouteProps, RouteProps>) {
  const { isAuth, isLoading } = useSelector(({ user }: RootState) => user);

  return (
    <Route
      {...rest}
      render={() => {
        if (isLoading) return null;
        if (isAuth) return children;
        return <Redirect to="/" />;
      }}
    />
  );
}

export default PrivateRoute;
