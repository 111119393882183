import styled from 'styled-components';

export const Container = styled.div<{
  contentWidth: string;
  groupSpace: string;
}>`
  flex: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${({ contentWidth }) => contentWidth};

  > .s3Pack__group + .s3Pack__group {
    margin-left: ${({ groupSpace }) => groupSpace};
  }
`;
