import styled, { css } from 'styled-components';
import { a } from '@react-spring/web';

function getColor(type: Toast.TToastType) {
  switch (type) {
    case 'success':
      return css`
        color: #326CF9;
        background-color: #EEF8FF;
      `;
    case 'error':
      return css`
        color: #E20724;
        background-color: #FFF4F4;
      `;
    case 'info':
      return css`
        color: #fff;
        background-color: #222;
      `;
    default:
      return '';
  }
}

export const MessageWrap = styled(a.div)<{ $type: Toast.TToastType; }>`
  display: grid;
  grid-template-columns: 24px minmax(0, 1fr) 24px;
  grid-column-gap: 8px;
  padding: 16px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transform-origin: top center;
  overflow: hidden;
  ${({ $type }) => getColor($type)};

  @media only screen and (min-width: 601px) {
    width: 520px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
`;

export const RemoveBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
`;
