import { create } from 'zustand';
import { nanoid } from 'nanoid';

import type { ReactNode } from 'react';

import isMobile from './utils/is-mobile';

interface ToastState {
  updateNum: number;
  mobileMessage: ReactNode | ReactNode[];
  toasts: Toast.ToastItem[];
  add: (type: Toast.TToastType, content: ReactNode | ReactNode[]) => void;
  remove: (id: string) => void;
  removeMessage: () => void;
}

export const useToastStore = create<ToastState>(set => ({
  updateNum: 0,
  mobileMessage: '',
  toasts: [],
  add: (type, content) => {
    if (content !== 0 && !content) return;

    const toast = {
      id: nanoid(),
      type,
      content,
    };

    if (isMobile()) {
      set(prev => ({
        updateNum: prev.updateNum + 1,
        mobileMessage: content,
      }));

    } else {
      set(prev => ({
        toasts: [...prev.toasts, toast],
      }));
    }
  },
  remove: id => {
    set(prev => ({
      toasts: prev.toasts.filter(t => t.id !== id),
    }));
  },
  removeMessage: () => {
    set(() => ({
      mobileMessage: '',
    }));
  },
}));

