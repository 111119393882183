import { useEffect } from 'react';

function useInitVh() {
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize, false);

    return () => {
      window.removeEventListener('resize', resize, false);
    };
  }, []);

  function resize() {
    const vh = +(window.innerHeight * 0.01).toFixed(10);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

export default useInitVh;
