import { StateCreator } from 'zustand';

export const initPosition: StoreMap.Position = {
  bbox: undefined,
  center: {
    lat: 37.494367328004216,
    lng: 127.01446798508894,
  },
  zoom: 11,
};

export const createPositionSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreMap.Position
> = set => ({
  ...initPosition,
});
