import { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ga from '~/utils/ga';
import useApiCall from '~/hooks/useApiCall';

function useProfile() {
  const _wrapper = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{
    type: Map.TPathType,
    type2?: string,
    id?: 'drawing' | 'distance';
  }>('/map/:type/:type2/:id?');

  const dispatch = useDispatch();

  const { get } = useApiCall();

  useEffect(() => {
    if (isOpen) {
      ga('GNB', '계정정보', 'GNB');
      document.addEventListener('mousedown', mouseDown, false);
    } else {
      document.removeEventListener('mousedown', mouseDown);
    }
  }, [isOpen]);

  const mouseDown = useCallback(e => {
    if (_wrapper.current && !_wrapper.current.contains(e.target)) {
      setOpen(() => {
        document.removeEventListener('mousedown', mouseDown);
        return false;
      });
    }
  }, []);

  function menuHandler() {
    setOpen(open => !open);
  }

  function clickSms() {
    ga('GNB', '메뉴클릭', '계정정보_문자문의');
  }

  function clickCall() {
    ga('GNB', '메뉴클릭', '계정정보_전화문의');
  }

  function clickAccount() {
    ga('GNB', '메뉴클릭', '계정정보_내정보');
  }

  async function logout() {
    try {
      await get('/api/3/user/logout');

      dispatch.user.reset();

      ga('GNB', '메뉴클릭', '계정정보_로그아웃');

      if (match?.params.type2 === 'drawing') {
        history.replace({
          ...location,
          pathname: `/map/${match?.params.type}`
        });

      } else if (location.pathname.indexOf('/user/report') > -1) {
        history.replace('/');

      } else {
        dispatch.user.checkUrl(true);
      }

    } catch(err) {
      console.log(err);
    }
  }

  return {
    _wrapper, isOpen,
    menuHandler,
    clickSms,
    clickCall,
    clickAccount,
    logout,
  };
}

export default useProfile;
