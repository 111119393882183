/**
 * text가 빈칸("") 또는 null 또는 undefined 인지 여부
 */
function isEmpty(text: any = ''): boolean {
  if (text === undefined) return true;
  if (text === null) return true;
  if (text === '') return true;
  if (JSON.stringify(text) === '{}') return true;
  if (JSON.stringify(text) === '[]') return true;

  return false;
}

export default isEmpty;
