function convertRegion(list: Search.Region[]): Search.SearchItem[] {
  return list.map(item => {
    return {
      type: 'region',
      id: item.code,
      name: item.name,
      fullName: item.fullName,
      location: item.location,
      zoom: item.zoom,
    };
  });
}

export default convertRegion;
