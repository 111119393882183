import styled from 'styled-components';

export const Header = styled.header`
  flex: none;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  z-index: 999;
  
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

export const LogoLink = styled.a`
  flex: none;
  margin-right: 16px;
`;

export const MenuWrap = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const AccountContainer = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

export const LoginBtn = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 16px;
  ${({ theme }) => theme.font.body3}
  font-weight: 700;
  color: ${({ theme }) => theme.color.gray[900]};
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  border-radius: 2px;
  background-color: transparent;

  > span {
    display: block;
    margin: 0 8px;
    width: 1px;
    height: 12px;
    background-color: ${({ theme }) => theme.color.gray[400]};
    position: relative;
  }

  &:hover {
    color: ${({ theme }) => theme.color.gray[900]};
    background-color: ${({ theme}) => theme.color.gray[50]};
  }
  
  & + & {
    margin-left: 8px;
  }
`;
