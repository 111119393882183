import styled from 'styled-components';

export const DayGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 28px);
  column-gap: 6px;
  margin-bottom: 6px;
  list-style: none;
  
  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 24px;
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.body3};
    font-weight: 400;
  }
`;

export const DateGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 28px);
  grid-template-rows: repeat(auto-fill, 28px);
  column-gap: 6px;
  row-gap: 4px;
  list-style: none;
  
  > li {
    width: 28px;
    height: 28px;
  }
`;

interface DateInfo {
  isToday: boolean;
  isActive: boolean;
}

export const DateBtn = styled.button<DateInfo>`
  width: 100%;
  height: 100%;
  padding: 0;
  color: ${({ isActive, isToday, theme }) => isActive ? theme.color.white : isToday ? theme.color.blue[500] : theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  font-weight: ${({ isActive, theme }) => isActive ? 700 : 400};
  border: 1px solid ${({ isActive, theme }) => isActive ? theme.color.blue[500] : 'transparent'};
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : 'transparent'};
  
  @media (hover: hover) and (pointer: fine) {
    &:not(:disabled):hover {
      border-color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : theme.color.gray[400]};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray[400]};
    ${({ theme }) => theme.font.body3};
    font-weight: 400;
    border: 1px solid transparent;
    background-color: transparent;
    cursor: default;
  }
`;