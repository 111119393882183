import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Dispatch } from '~/store';

import { siteTitle, kakaoPixelTrackId } from '~/enum/config';
import useApiCall from '~/hooks/useApiCall';

ReactGA.initialize([
  { trackingId: 'G-QMSMS2LS99' },
]);

const { kakaoPixel } = window;

function useCheck() {
  const location = useLocation();
  const [prevLocation, setLocation] = useState(location);
  const [prevLocationForGA, setLocationForGA] = useState<typeof location | null>(null);

  const dispatch = useDispatch<Dispatch>();
  const { get } = useApiCall();

  useEffect(() => {
    if (!location.hash) {
      if (
        location.pathname.indexOf('/room/') < 0 &&
        location.pathname.indexOf('/complex/') < 0 &&
        location.pathname.indexOf('/sale-in-lots/') < 0
      ) {
        document.title = siteTitle;
      }

      setLocation(location);
      setLocationForGA(location);

      const path = location.pathname;

      if (path !== '/search' && prevLocationForGA?.pathname !== path) {
        ReactGA.send({ hitType: 'pageview', page: path });
        kakaoPixel?.(kakaoPixelTrackId).pageView();
        window.scrollTo(0, 0);
      }
    }
  }, [location]);

  useEffect(() => {
    if (prevLocation.pathname === location.pathname) {
      getUserInfo();

    } else if (
      !(prevLocation.pathname.indexOf('/sales') > -1 && location.pathname.indexOf('/sales') > -1) &&
      !(prevLocation.pathname.indexOf('/search') > -1 && location.pathname.indexOf('/search') > -1) &&
      !(prevLocation.pathname.indexOf('/manage') > -1 && location.pathname.indexOf('/manage') > -1) &&
      !(prevLocation.pathname.indexOf('/service') > -1 && location.pathname.indexOf('/service') > -1) &&
      !(prevLocation.pathname.indexOf('/user') > -1 && location.pathname.indexOf('/user') > -1) &&
      !(prevLocation.pathname.indexOf('/favorite') > -1 && location.pathname.indexOf('/favorite') > -1)
    ) {
      // 같은 페이지에서 돌아다닐때는 유저정보를 새로받을 필요가 없다.
      getUserInfo();
    }
  }, [location.pathname]);


  async function getUserInfo() {
    try {
      const res = await get('/api/3/user/info');

      dispatch.user.update({
        ...res,
        isAuth: true,
        isLoading: false,
        profileReset: new Date().valueOf(),
        isCertRenewalPopup: res.isPhoneNumberCheck && location.pathname.indexOf('/manage') > -1 ? 'check' : 'close',
      });

    } catch(err) {
      console.log(err);
      // 유저정보를 받아오는데 실패함 -> 로그아웃상태
      // 비로그인 상태에서 로그인후 볼 수 있는 페이지에 있는지 체크후 리다이렉션
      dispatch.user.update({ isLoading: false });
      dispatch.user.checkUrl(false);
    }
  }
}

export default useCheck;
