import styled from 'styled-components';
import { resultBtnStd } from '../styled';

export const Content = styled.div`
  padding: 0 16px 40px;
`;

export const GroupContent = styled.div`
  
  & + & {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  }
`;

export const RegionBtn = styled.button`
  ${resultBtnStd};
`;

export const SubwayBtn = styled.button`
  ${resultBtnStd};
  grid-template-columns: minmax(0, 1fr) 100px;
  
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 4px;
    padding: 3px;
  }
`;

export const NumTag = styled.p<{ $color: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.font.caption2};
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 20px;
`;

export const TextTag = styled.p<{ $color: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 7px;
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.font.caption2};
  font-weight: 400;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 20px;
`;

export const UnivBtn = styled.button`
  ${resultBtnStd};
`;
