import React, { forwardRef, useRef, useMemo, useLayoutEffect } from 'react';

import type { BaseInputProps } from './types';

import {
  InputWrap,
  Input,
  LeftIconBtn, LeftIconWrap,
  RightIconBtn, RightIconWrap,
  Unit,
} from './styled';

export const BaseInputUIComponent = forwardRef<HTMLInputElement, BaseInputProps>(({
  contentWidth = '100%',
  contentHeight = '44px',
  borderRadius = '2px',
  leftIcon,
  onLeftIconClick,
  rightIcon,
  onRightIconClick,
  max,
  hideMax = false,
  unit,
  isError = false,
  value ,
  disabled = false,
  style,
  ...props
}, ref) => {
  const _unit = useRef<HTMLParagraphElement>(null);
  const _input = useRef<HTMLInputElement | null>(null);

  const leftSpace = useMemo(() => leftIcon ? 40 : 16, [leftIcon]);

  const cnt = useMemo(() => {
    if (value !== undefined && max) {
      return typeof value === 'number' ? value.toString().length : value.length;
    }
    return 0;
  }, [max, value]);

  useLayoutEffect(() => {
    if (unit || (max !== undefined && !hideMax)) {
      calcUnitSpace();

    } else if (rightIcon !== undefined) {
      if (_input.current) {
        _input.current.style.paddingRight = '40px';
      }

    } else {
      if (_input.current) {
        _input.current.style.paddingRight = '16px';
      }
    }
  }, [cnt, unit, max, hideMax, rightIcon]);

  function calcUnitSpace() {
    if (_unit.current) {
      const measure = () => {
        if (_unit.current) {
          const rect = _unit.current.getBoundingClientRect();

          if (_input.current) {
            _input.current.style.paddingRight = `${rect.width + 24}px`;
          }
        }
      };

      // 렌더링 완료 후 실행
      requestAnimationFrame(measure);

    } else {
      setTimeout(() => {
        calcUnitSpace();
      }, 60);
    }
  }

  return (
    <InputWrap
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      isDisabled={disabled}
      style={style}
    >
      {leftIcon && (
        onLeftIconClick ? (
          <LeftIconBtn
            onClick={onLeftIconClick}
            disabled={disabled}
          >
            {leftIcon}
          </LeftIconBtn>
        ) : (
          <LeftIconWrap>
            {leftIcon}
          </LeftIconWrap>
        )
      )}

      <Input
        ref={node => {
          _input.current = node;

          if (typeof ref === 'function') {
            ref(node);

          } else if (ref) {
            ref.current = node;
          }
        }}
        leftSpace={leftSpace}
        $borderRadius={borderRadius}
        isError={isError}
        value={value}
        disabled={disabled}
        autoComplete="off"
        {...props}
      />

      {max && !hideMax && value !== undefined && !disabled ? (
        <Unit ref={_unit} isActive={cnt > 0} isError={cnt > max}>
          {cnt}/{max}
        </Unit>
      ) : unit ? (
        <Unit ref={_unit} isActive={true}>
          {unit}
        </Unit>
      ) : rightIcon && (
        onRightIconClick ? (
          <RightIconBtn
            onClick={onRightIconClick}
            disabled={disabled}
          >
            {rightIcon}
          </RightIconBtn>
        ) : (
          <RightIconWrap>
            {rightIcon}
          </RightIconWrap>
        )
      )}
    </InputWrap>
  );
});

BaseInputUIComponent.displayName = 'BaseInput';
