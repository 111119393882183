import React, { ReactNode, ReactPortal, CSSProperties } from 'react';
import { createPortal } from 'react-dom';

import usePopup from './usePopup';
import useEscToClose from '../../useEscToClose';

import {
  PopupContainer,
  PopupContent,
} from './styled';

interface Props {
  popupWidth: number;
  closePopup: () => void;
  escToClose?: boolean;
  overlayClickToClose?: boolean;
  isLoading?: boolean;
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
}

export function PopupComponent({
  popupWidth,
  closePopup,
  escToClose = true,
  overlayClickToClose = false,
  isLoading = false,
  children,
  style,
}: Props): ReactPortal {
  const {
    id,
    idx,
    isActive,
  } = usePopup();

  useEscToClose({
    isActive,
    closePopup,
    escToClose,
    isLoading,
  });

  return createPortal(
    <PopupContainer
      $zIndex={isActive ? idx + 2001 : idx + 1001}
      onClick={overlayClickToClose ? isLoading ? undefined : closePopup : undefined}
    >
      <PopupContent
        id={id}
        onClick={e => e.stopPropagation()}
        $popupWidth={popupWidth}
        $isActive={isActive}
        style={style}
      >
        {children}
      </PopupContent>
    </PopupContainer>,
    document.getElementById('modal-container') as HTMLDivElement
  );
}

PopupComponent.displayName = 'Popup';
