import React, { forwardRef, ChangeEvent } from 'react';

import type { SelectProps } from './types';

import { Select } from './styled';

export const SelectUIComponent = forwardRef<HTMLSelectElement, SelectProps>(({
  contentWidth = '100%',
  onChange,
  onChangeNative,
  isError = false,
  children,
  style,
  ...props
}, ref) => {
  function change(e: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.currentTarget;
    onChangeNative?.(e);
    onChange?.({ name, value });
  }

  return (
    <Select
      ref={ref}
      contentWidth={contentWidth}
      isError={isError}
      onChange={change}
      style={style}
      {...props}
    >
      {children}
    </Select>
  );
});

SelectUIComponent.displayName = 'Select';
