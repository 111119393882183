import styled, { css } from 'styled-components';
import profileImg from './images/profile.svg';

type TProfile = {
  size: number;
  isShow: boolean;
  isActive: boolean;
};

export const Profile = styled.div<TProfile>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  user-select: none;
  position: relative;
  cursor: ${({ isActive }) => isActive && 'pointer'};
  
  ${({ isShow }) => !isShow && css`
    background: url(${profileImg}) center center no-repeat;
    background-size: cover;
  `}

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    opacity: ${({ isShow }) => isShow ? 1 : 0};
  }
`;
