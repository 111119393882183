import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createPositionSlice, initPosition } from './position';
import { createSearchSlice, initSearch } from './search';
import { createFilterSlice } from './filter';
import { createAveragePriceSlice, initAveragePrice } from './average-price';

import { initOnetwoFilter } from './filter/onetwo';
import { initHouseFilter } from './filter/house';
import { initOfficetelFilter } from './filter/officetel';
import { initAptFilter } from './filter/apt';
import { initSaleInLotsFilter } from './filter/sale-in-lots';

export const useMapStore = create<StoreMap.State>()(
  immer((set, get, store) => ({
    isMarkerReady: '',
    isListReady: '',
    listType: '',

    center: {
      name: '',
    },

    // [모웹전용] 필터 열림 여부
    isOpenFilter: false,
    // [모웹전용] 필터 열림 여부
    openType: false,
    // [모웹전용] 현재위치 좌표 값
    userPosition: undefined,

    position: createPositionSlice(set, get, store),
    search: createSearchSlice(set, get, store),
    filter: createFilterSlice(set, get, store),

    averagePrice: createAveragePriceSlice(set, get, store),

    reset: () => {
      set(draft => {
        draft.isMarkerReady = '';
        draft.isListReady = '';
        draft.listType = '';
        draft.center.name = '';

        draft.isOpenFilter = false;
        draft.openType = false;
        draft.userPosition = undefined;

        draft.position = {
          ...draft.position,
          ...initPosition,
        };

        draft.search = {
          ...draft.search,
          ...initSearch,
        };

        draft.averagePrice = {
          ...draft.averagePrice,
          ...initAveragePrice,
        };

        draft.filter.onetwo = {
          ...draft.filter.onetwo,
          ...initOnetwoFilter,
        };

        draft.filter.house = {
          ...draft.filter.house,
          ...initHouseFilter,
        };

        draft.filter.officetel = {
          ...draft.filter.officetel,
          ...initOfficetelFilter,
        };

        draft.filter.apt = {
          ...draft.filter.apt,
          ...initAptFilter,
        };

        draft.filter.saleInLots = {
          ...draft.filter.saleInLots,
          ...initSaleInLotsFilter,
        };
      });
    },
  })),
);

export {
  initPosition,
  initSearch,
  initAveragePrice,
  initOnetwoFilter,
  initHouseFilter,
  initOfficetelFilter,
  initAptFilter,
  initSaleInLotsFilter,
};
