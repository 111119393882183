import React, { useRef, useState, ReactElement } from 'react';

import { Profile } from './styled';

interface Props {
  imgUrl: string | null;
  size: number;
  openPreview?: boolean;
  children?: ReactElement | ReactElement[];
  onClick?: () => void;
  [props: string]: any;
}

function ProfileImageComponent({
  children,
  imgUrl,
  size,
  onClick,
  openPreview = false,
  ...props
}: Props) {
  const _wrap = useRef<HTMLDivElement>(null);
  const _img = useRef<HTMLImageElement>(null);
  const [isShow, setShow] = useState(false);
  const isActive = isShow && openPreview && !!imgUrl;

  function onLoad() {
    setShow(true);
  }

  function onError(e: any) {
    setShow(false);
  }

  function clickHandler() {
    if (onClick) onClick();

    if (isActive) {
      window.open(imgUrl, '_blank');
    }
  }

  return (
    <Profile
      {...props}
      ref={_wrap}
      size={size}
      isShow={isShow}
      isActive={isActive}
      onClick={clickHandler}
    >
      {imgUrl && (
        <img
          ref={_img}
          src={imgUrl}
          alt=""
          draggable={false}
          onLoad={onLoad}
          onError={onError}
        />
      )}
      {children}
    </Profile>
  );
}

ProfileImageComponent.defaultProps = {
  size: 140,
};

export default ProfileImageComponent;
