import styled from 'styled-components';

export const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 8px;
  padding: 0 9px;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.caption1};
  font-weight: 700;
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.blue[500]};
  
  > svg {
    margin-left: 4px;
  }
`;
