import styled from 'styled-components';
import { a } from '@react-spring/web';

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
  height: 100%;
  position: relative;
`;

export const Label = styled.label`
  flex: none;
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const SearchIcon = styled(a.div)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Input = styled.input<{
  $isActive: boolean;
}>`
  width: 100%;
  padding: ${({ $isActive }) => $isActive ? '7px 15px 7px 37px' : '7px 37px 7px 15px'};
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  border-radius: 42px;
  background-color: #fff;
  transition: padding 250ms cubic-bezier(0.33, 1, 0.68, 1);
  
  &:focus {
    padding: 7px 41px 7px 37px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.gray[600]};
    user-select: none;
  }
`;

export const InputRightIconWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const RemoveBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export const ResultContainer = styled.section<{
  $isShowResult: boolean;
}>`
  overflow: hidden;
  position: absolute;
  top: 79px;
  left: 0;
  width: 1180px;
  border: ${({ $isShowResult, theme }) => $isShowResult ? `1px solid ${theme.color.gray[300]}` : '0'};
  border-radius: 0 0 12px 12px;
  background: #fff;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
`;
