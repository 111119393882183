interface Chunk {
  highlight: boolean;
  start: number;
  end: number;
}

interface FindArgs {
  text: string;
  searchWords: string[];
  caseSensitive?: boolean;
  sanitize?: (text: string) => string;
}

// 문장에서 검색한단어 찾기
function findWords({
  text,
  searchWords,
  sanitize = (text: string) => text,
  caseSensitive = false,
}: FindArgs): Chunk[] {
  text = sanitize(text);

  // 필터 : empty 문자를 제거
  return searchWords.filter(searchWord => searchWord).reduce((chunks, searchWord) => {
    searchWord = sanitize(searchWord);

    const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

    let match;

    while ((match = regex.exec(text))) {
      let start = match.index;
      let end = regex.lastIndex;

      // 매칭된게 있을 경우에만 리턴한다.
      if (end > start) {
        chunks.push({ highlight: true, start, end });
      }

      // Prevent browsers like Firefox from getting stuck in an infinite loop
      // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }
    }

    return chunks;
  }, [] as Chunk[]);
}

// 중복인 청크 하나로 결합(제거)
function combineChunks(chunks: Chunk[]) {
  const sortedChunks = chunks.sort((a, b) => a.start - b.start);

  return sortedChunks.reduce((result, chunk) => {
    if (result.length === 0) return [chunk];

    // 청크가 중복 되는지 체크하기 위해 배열의 마지막 chunk를 가져온다.
    const prevChunk = result.pop();

    if (!prevChunk) return result;

    if (chunk.start <= prevChunk.end) {
      // prevChunk가 chunk를 모두 포함하는 경우일 수 있으니, 가장 큰 end 값을 넣어준다.
      const endIndex = Math.max(prevChunk.end, chunk.end);
      result.push({
        highlight: true,
        start: prevChunk.start,
        end: endIndex
      });

    } else {
      result.push(prevChunk, chunk);
    }

    return result;
  }, [] as Chunk[]);
}

// 강조할 텍스트 사이에 일반 텍스트를 나타낼 chunk를 추가한다.
function findInChunks(totalLength: number) {
  return (chunks: Chunk[]) => {
    const allChunks: Chunk[] = [];

    const append = (start: number, end: number, highlight: boolean) => {
      if (end - start > 0) {
        allChunks.push({
          start,
          end,
          highlight
        });
      }
    };

    if (chunks.length === 0) {
      append(0, totalLength, false);

    } else {
      let lastIndex = 0;

      chunks.forEach(chunk => {
        append(lastIndex, chunk.start, false);
        append(chunk.start, chunk.end, true);
        lastIndex = chunk.end;
      });

      append(lastIndex, totalLength, false);
    }

    return allChunks;
  };
}

const pipe = (props: any, ...fns: Function[]) => {
  return fns.reduce((acc, fn) => fn(acc), props);
};

function findAll(props: FindArgs): Chunk[] {
  const textLength = props.text?.length ?? 0;

  return pipe(
    props,
    findWords,
    combineChunks,
    findInChunks(textLength),
  );
}



export default findAll;
