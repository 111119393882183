import React, { useRef, useState, useEffect } from 'react';
import { useSpringRef, useSpring, useChain } from '@react-spring/web';

import { useToastStore } from '../../store';

import Timer from '../../utils/timer';

import { MessageWrap, Icon, Content, RemoveBtn } from './styled';

interface Props {
  idx: number;
  total: number;
  maxShowCount: number;
  toast: Toast.ToastItem;
  timeout: number;
}

function ToastMessageComponent({
  idx,
  total,
  maxShowCount,
  toast,
  timeout,
}: Props) {
  const _timer = useRef(new Timer(remove, timeout));
  const _wrap = useRef<HTMLDivElement>(null);
  const _height = useRef(0);

  const [isShow, setShow] = useState(true);

  useEffect(() => {
    _timer.current.resume();

    if (_wrap.current) {
      _height.current = _wrap.current.offsetHeight;
    }

    return () => {
      _timer.current.clear();
    };
  }, []);

  useEffect(() => {
    if (total > maxShowCount) {
      if (Math.abs(total - maxShowCount) > idx) {
        remove();
      }
    }
  }, [total]);

  const showApi = useSpringRef();
  const closeApi = useSpringRef();

  const { right } = useSpring({
    ref: showApi,
    from: {
      right: '-100%',
    },
    to: {
      right: '0%'
    },
    config: {
      duration: 150,
    }
  });

  const {
    height,
    marginBottom,
    padding,
    opacity,
  } = useSpring({
    ref: closeApi,
    from: {
      height: `${_height.current}px`,
      marginBottom: '20px',
      padding: '16px 16px',
      opacity: 1,
    },
    to: {
      height: '0px',
      marginBottom: '0px',
      padding: '0px 16px',
      opacity: 0.1,
    },
    config: {
      duration: 150,
    },
    onRest: () => {
      useToastStore.getState().remove(toast.id);
    }
  });

  useChain(isShow ? [showApi] : [closeApi]);

  function pause() {
    _timer.current.pause();
  }

  function resume() {
    _timer.current.resume();
  }

  function remove() {
    setShow(false);
  }

  return (
    <MessageWrap
      ref={_wrap}
      $type={toast.type}
      onMouseEnter={pause}
      onMouseLeave={resume}
      style={{
        height: isShow ? 'auto' : height,
        marginBottom,
        padding,
        right,
        opacity,
      }}
    >
      <Icon>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            fill={toast.type === 'success' ? '#00A9FF' : toast.type === 'error' ? '#F87680' : '#656565'}
            cx="12"
            cy="12"
            r="9"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5081 13.0899C11.5504 13.3231 11.7546 13.5 12 13.5C12.2762 13.5 12.5 13.2761 12.5 13V8.5L12.492 8.41012C12.4496 8.17688 12.2455 8 12 8C11.7239 8 11.5 8.22386 11.5 8.5V13L11.5081 13.0899ZM12 16.2C12.3314 16.2 12.6 15.9314 12.6 15.6C12.6 15.2686 12.3314 15 12 15C11.6687 15 11.4 15.2686 11.4 15.6C11.4 15.9314 11.6687 16.2 12 16.2Z"
          />
        </svg>
      </Icon>

      <Content>
        {toast.content}
      </Content>

      <RemoveBtn onClick={remove}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={toast.type === 'info' ? '#fff' : '#222'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.04169 7.34595C7.84682 7.21095 7.5774 7.23023 7.40383 7.4038C7.20857 7.59906 7.20857 7.91565 7.40383 8.11091L11.2929 12L7.40383 15.8891L7.34598 15.9583C7.21098 16.1532 7.23027 16.4226 7.40383 16.5962C7.59909 16.7915 7.91568 16.7915 8.11094 16.5962L12 12.7071L15.8891 16.5962L15.9584 16.654C16.1532 16.789 16.4227 16.7698 16.5962 16.5962C16.7915 16.4009 16.7915 16.0843 16.5962 15.8891L12.7071 12L16.5962 8.11091L16.6541 8.04166C16.7891 7.84679 16.7698 7.57737 16.5962 7.4038C16.401 7.20854 16.0844 7.20854 15.8891 7.4038L12 11.2929L8.11094 7.4038L8.04169 7.34595Z"
          />
        </svg>
      </RemoveBtn>
    </MessageWrap>
  );
}

export default ToastMessageComponent;
