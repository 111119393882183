import RawType from '~/utils/raw-type';
import isEmpty from '~/utils/valid/is-empty';
import isZero from '~/utils/valid/is-zero';

/**
 * prefix - 접두사
 * postfix - 접미사
 * */
type TUnit = {
  prefix?: string;
  postfix?: string;
}

function isString(x: any): x is string { return RawType(x) === 'String'; }
/**
 * @param {any} value
 * @param {string|TUnit} unit
 * */
function withUnit(value: any, unit: string | TUnit = ''): string {
  if (isEmpty(value) || isZero(value)) return '-';

  if (isString(unit)) {
    return `${value}${unit}`;

  } else {
    const { prefix = '', postfix = '' } = unit;

    return `${prefix}${value}${postfix}`;
  }
}

export default withUnit;
