import { css } from 'styled-components';

export const layout = css`
  width: 100%;

  > div {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
`;

export const mobileLayout = css`

  > div {
    width: 100%;
    position: relative;
  }
`;
