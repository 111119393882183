import { Fragment, useMemo, createElement } from 'react';

import findAll from '~/utils/search/highlight-words';

interface Props {
  text: string;
  searchWords: string[];
  caseSensitive?: boolean;
  sanitize?: (text: string) => string;
  markClassName?: string;
}

function HighlightWordsComponent({
  text,
  searchWords,
  caseSensitive,
  sanitize,
  markClassName,
}: Props) {
  const wordElems = useMemo(() => {
    const chunks = findAll({
      text,
      searchWords,
      caseSensitive,
      sanitize,
    });

    return chunks.map(chunk => {
      const word = text.slice(chunk.start, chunk.end);

      if (chunk.highlight) {
        return createElement('mark', { className: markClassName }, word);

      } else {
        return createElement(Fragment, null, word);
      }
    });
  }, [text, searchWords, caseSensitive, sanitize]);

  return createElement(Fragment, null, ...wordElems);
}

export default HighlightWordsComponent;
