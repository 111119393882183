import React, { ReactElement } from 'react';
import { useLocation, Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parse } from '~/utils/query-string';

import { RootState } from '~/store';

interface PublicRouteProps {
  children: ReactElement | ReactElement[];
}

function PublicRoute({ children, ...rest }: MergeType<PublicRouteProps, RouteProps>) {
  const { isAuth, isLoading } = useSelector(({ user }: RootState) => user);
  const location = useLocation();
  const query: {
    return_url?: string;
  } = parse(location.search);

  return (
    <Route
      {...rest}
      render={({ match }) => {
        if (isLoading) return null;
        if (!isAuth) return children;
        if (match.params.step === 'complete') return children;
        return <Redirect to={query?.return_url ? decodeURIComponent(query?.return_url) : '/'} />;
      }}
    />
  );
}

export default PublicRoute;
