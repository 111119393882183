import styled, { css } from 'styled-components';

import type { TColor, TSize, TFontWeight } from '../types';
import { getSize } from '../utils';

function getColor(color: TColor) {
  switch (color) {
    case 'blue':
      return css`
        background-color: ${({ theme }) => theme.color.blue['500']};
        
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.blue['600']}; 
          }
        }
      `;

    case 'red':
      return css`
        background-color: ${({ theme }) => theme.color.red['500']};

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.red['600']};
          }
        }
      `;

    case 'gray':
      return css`
        background-color: ${({ theme }) => theme.color.gray['900']};

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.gray['800']};
          }
        }
      `;
  }

  return '';
}

export const solidBtnStd = css<{
  contentWidth: string;
  btnColor: TColor;
  btnSize: TSize;
  fontWeight?: TFontWeight
}>`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ contentWidth }) => contentWidth};
  padding: 0 16px;
  color: ${({ theme }) => theme.color.white};
  border: 0;
  border-radius: 2px;
  transition: all 150ms ease-out;
  cursor: pointer;
  
  ${({ btnSize, fontWeight }) => getSize(btnSize, fontWeight)};
  ${({ btnColor }) => getColor(btnColor)};

  > svg {
    flex: none;
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.color.gray['400']};
    cursor: default;

    > svg {
      circle[fill],
      ellipse[fill],
      line[fill],
      path[fill],
      polygon[fill],
      polyline[fill],
      rect[fill] {
        fill: ${({ theme }) => theme.color.white} !important;
      }

      circle[stroke],
      ellipse[stroke],
      line[stroke],
      path[stroke],
      polygon[stroke],
      polyline[stroke],
      rect[stroke] {
        stroke: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

export const SolidButton = styled.button`
  ${solidBtnStd};
`;

export const Text = styled.span<{
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
}>`
  margin-left: ${({ hasLeftIcon }) => hasLeftIcon ? '4px' : '0'};
  margin-right: ${({ hasRightIcon }) => hasRightIcon ? '4px' : '0'};
`;
