import styled from 'styled-components';

export const PopupContainer = styled.div<{
  $zIndex: number;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: ${({ $zIndex }) => $zIndex};

  @media only screen and (max-width: 320px) {
    padding: 16px;
  }
`;

export const PopupContent = styled.div<{
  $maxWidth: number;
  $isActive: boolean;
}>`
  flex: none;
  margin: auto;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  border: 1px solid ${({ $isActive, theme }) => $isActive ? 'transparent' : theme.color.gray[200]};
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  z-index: 1002;
`;
