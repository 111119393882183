import styled, { css } from 'styled-components';

import type { TTitleSize } from '../../types';

export const TitleWrap = styled.header<{
  titleSize: TTitleSize;
  hasFakeTitle: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ titleSize }) => titleSize === 'sm' ? '4px' :  '8px'};
  
  ${({ hasFakeTitle, titleSize }) => hasFakeTitle && (
    titleSize === 'sm' ? css`
      height: 20px;
    ` : css`
      height: 24px;
    `
  )}
`;

export const Title = styled.h1<{
  titleSize: TTitleSize;
  isDisabled: boolean;
}>`
  color: ${({ titleSize, isDisabled, theme }) => theme.color.gray[isDisabled ? 500 : titleSize === 'sm' ? 700 : 900]};
  ${({ titleSize, theme }) => titleSize === 'sm' ? theme.font.caption1 : theme.font.body3};
  font-weight: 700;
`;

export const Star = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.color.blue[500]};
`;
