import React from 'react';
import { createPortal } from 'react-dom';

import { useToastStore } from './store';

import isMobile from './utils/is-mobile';

import WebMessage from './web/message';
import MobileMessage from './mobile/message';

import { WebContainer, MobileContainer } from './styled';

interface Props {
  // [공용] : 화면에 노출되는 시간
  timeout?: number;
  // [PC전용] : 최대 노출 갯수
  maxShowCount?: number;
}

export function ToastPortal({
  timeout,
  maxShowCount = 3,
}: Props) {
  const toasts = useToastStore(store => store.toasts);
  const mobileMessage = useToastStore(store => store.mobileMessage);

  if (isMobile()) {
    return createPortal(
      <MobileContainer $isShow={mobileMessage !== ''}>
        {mobileMessage && (
          <MobileMessage
            message={mobileMessage}
            timeout={timeout ?? 3000}
          />
        )}
      </MobileContainer>,
      document.body,
    );
  }

  return createPortal(
    <WebContainer $isShow={toasts.length > 0}>
      {toasts.map((toast, idx) => {
        return (
          <WebMessage
            key={toast.id}
            idx={idx}
            total={toasts.length}
            maxShowCount={maxShowCount}
            toast={toast}
            timeout={timeout ?? 3000}
          />
        );
      })}
    </WebContainer>,
    document.body,
  );
}
