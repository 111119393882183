import React, { memo } from 'react';
import isSameDay from 'date-fns/esm/isSameDay';
import type { DateItem } from '~/utils/get-date-of-month';

import { DayGrid, DateBtn, DateGrid } from './styled';

const DAY_TEXT = ['일', '월', '화', '수', '목', '금', '토'];

interface Props {
  selectDate: string;
  days: DateItem[];
  onClick(date: Date): void;
}

function DateComponent({
  selectDate, days, onClick,
}: Props) {
  return (
    <>
      <DayGrid>
        {DAY_TEXT.map(text => <li key={text}>{text}</li>)}
      </DayGrid>

      <DateGrid>
        {days.map((day, key) => {
          const isActive = day.date && selectDate !== '' ? isSameDay(day.date, new Date(selectDate)) : false;
          return (
            <li key={key}>
              {day.date && (
                <DateBtn
                  isToday={day.isToday}
                  isActive={isActive}
                  onClick={onClick.bind(null, day.date)}
                  disabled={day.isOutOfRange}
                >
                  {day.formatDate}
                </DateBtn>
              )}
            </li>
          );
        })}
      </DateGrid>
    </>
  );
}

export default memo(DateComponent);
