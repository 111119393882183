import RawType from '~/utils/raw-type';
/**
 * 전화 번호를 - 로 구분되도록 변경
 * 0212341234 -> 02-1234-1234
 * 031-123-1234 -> 031-123-1234
 * 휴대폰 번호를 - 로 구분되도록 변경
 * 01000000000 -> 010-0000-0000
 * 010-0000-0000 -> 010-0000-0000
 * 안심 번호를 - 로 구분되도록 변경
 * 050400000000 -> 0504-0000-0000
 * 0504-0000-0000 -> 0504-0000-0000
 * @param {*} num 번호
 */
function phoneFormat(num: string | number[] = ''): string {
  if (RawType(num) === 'Array') {
    num = (num as number[]).join('-');
  }
 
  const phoneRegex = /^(01[0|1|6|7|8|9])[- ]?(\d{3,4})[- ]?(\d{4})$/g;
  const safeRegex = /^(050[\d]{1})[- ]?(\d{4})[- ]?(\d{4,})$/g;
  const telRegex = /^(02|03[1|2|3]|04[1|2|3|4]|05[1|2|3|4|5]|06[1|2|3|4]|070)[- ]?(\d{3,4})[- ]?(\d{4})$/g;
  const contactRegex = /^(1[\d]{3})[- ]?(\d{4})$/g;

  if (phoneRegex.test(num as string)) return num?.toString().replace(phoneRegex, '$1-$2-$3') ?? num;
  if (safeRegex.test(num as string)) return num?.toString().replace(safeRegex, '$1-$2-$3') ?? num;
  if (telRegex.test(num as string)) return num?.toString().replace(telRegex, '$1-$2-$3') ?? num;
  if (contactRegex.test(num as string)) return num?.toString().replace(telRegex, '$1-$2') ?? num;

  return num as any;
}

export default phoneFormat;
