function convertComplex(list: Search.Complex[]): Search.SearchItem[] {
  return list.map(item => {
    return {
      type: item.complexType === 0 ? 'apt' : 'officetel',
      id: item.complexId,
      name: item.name,
      fullName: item.fullName,
      location: item.location,
      zoom: item.zoom,
      address: item.complexAddress,
      child: [
        {
          name: item.complexTypeStr,
          shortName: item.complexTypeStr,
          color: '#656565',
        },
      ],
    };
  });
}

export default convertComplex;
