import React, { forwardRef } from 'react';

import type { ButtonProps } from '../types';

import { LineButton, Text } from './styled';

export const LineButtonUIComponent = forwardRef<HTMLButtonElement, ButtonProps>(({
  contentWidth = 'auto',
  leftIcon,
  rightIcon,
  btnColor,
  btnSize,
  fontWeight,
  children,
  ...props
}, ref) => {
  return (
    <LineButton
      ref={ref}
      type="button"
      contentWidth={contentWidth}
      btnColor={btnColor}
      btnSize={btnSize}
      fontWeight={fontWeight}
      {...props}
    >
      {leftIcon}

      <Text
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
      >
        {children}
      </Text>

      {rightIcon}
    </LineButton>
  );
});

LineButtonUIComponent.displayName = 'LineButton';
