import React, { ReactNode, ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { to } from '@react-spring/web';

import useBottomSheet from './useBottomSheet';
import useEscToClose from '../../useEscToClose';

import { BottomSheetContainer, BottomSheetContent } from './styled';

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  escToClose?: boolean;
  overlayClickToClose?: boolean;
  isLoading?: boolean;
  children: ReactNode | ReactNode[];
}

export function BottomSheetComponent({
  isOpen,
  closePopup,
  escToClose = true,
  overlayClickToClose = true,
  isLoading = false,
  children
}: Props): ReactPortal | null {
  const {
    id,
    idx,
    isShow,
    isActive,
    y,
  } = useBottomSheet({
    isOpen,
  });

  useEscToClose({
    isActive,
    closePopup,
    escToClose,
    isLoading,
  });

  if (!isShow) return null;

  return createPortal(
    <BottomSheetContainer
      $zIndex={isActive ? idx + 2001 : idx + 1001}
      onClick={overlayClickToClose ? isLoading ? undefined : closePopup : undefined}
    >
      <BottomSheetContent
        id={id}
        onClick={e => e.stopPropagation()}
        style={{
          y: to(y, i => `${i}%`),
        }}
      >
        {children}
      </BottomSheetContent>
    </BottomSheetContainer>,
    document.getElementById('modal-container') as HTMLDivElement
  );
}

BottomSheetComponent.displayName = 'BottomSheet';
