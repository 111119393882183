import styled, { keyframes } from 'styled-components';

export const Container = styled.div<{ $isShow: boolean; }>`
  display: ${({ $isShow }) => $isShow ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Overlay = styled.div<{ $isShow: boolean; }>`
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${({ $isShow }) => $isShow ? fadeIn : fadeOut} 100ms ease-out;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
`;
