import styled from 'styled-components';

import type { TChipType, TChipColor } from '../types';

import getChipStyle from './utils/get-chip-style';

export const CircleChip = styled.div<{
  chipType: TChipType;
  chipColor: TChipColor;
  contentWidth: string;
}>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ contentWidth }) => contentWidth};
  height: 28px;
  padding: 0 11px;
  ${({ theme }) => theme.font.caption1};
  font-weight: 700;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 28px;
  
  ${({ chipType, chipColor }) => getChipStyle(chipType, chipColor)};
`;

export const DeleteBtn = styled.button`
  flex: none;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;
