import { useEffect } from 'react';

interface Props {
  isActive: boolean;
  closePopup: () => void;
  escToClose: boolean;
  isLoading: boolean;
}

function useEscToClose({
  isActive,
  closePopup,
  escToClose,
  isLoading,
}: Props) {
  useEffect(() => {
    if (isActive && escToClose) {
      document.addEventListener('keydown', onKeydown, false);
    }

    return () => {
      if (escToClose) {
        document.removeEventListener('keydown', onKeydown);
      }
    };
  }, [isActive, escToClose]);

  function onKeydown(e: KeyboardEvent) {
    if (!isLoading && isActive && e.code === 'Escape') {
      closePopup();
      document.removeEventListener('keydown', onKeydown);
    }
  }
}

export default useEscToClose;
