import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enableMapSet } from 'immer';

import store from '~/store';
import theme from '~/theme';

import { PopupPortal } from '~/components/modal2';
import { ToastPortal } from '~/components/toast';

import Routes from './routes';

enableMapSet();

const queryClient = new QueryClient();

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <PopupPortal />
          <ToastPortal />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
