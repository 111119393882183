import { css } from 'styled-components';

import type { TChipColor } from '../../types';

const iconStyle = css`
  > button > svg {
    circle[fill],
    ellipse[fill],
    line[fill],
    path[fill],
    polygon[fill],
    polyline[fill],
    rect[fill] {
      fill: ${({ theme }) => theme.color.white} !important;
    }

    circle[stroke],
    ellipse[stroke],
    line[stroke],
    path[stroke],
    polygon[stroke],
    polyline[stroke],
    rect[stroke] {
      stroke: ${({ theme }) => theme.color.white} !important;
    }
  }
`;

export function getDarkColor(color: TChipColor) {
  switch (color) {
    case 'gray':
      return css`
        color: ${({ theme }) => theme.color.white};
        border-color: ${({ theme }) => theme.color.gray[900]};
        background-color: ${({ theme }) => theme.color.gray[900]};
        
        ${iconStyle};
      `;

    case 'blue':
      return css`
        color: ${({ theme }) => theme.color.white};
        border-color: ${({ theme }) => theme.color.blue[500]};
        background-color: ${({ theme }) => theme.color.blue[500]};
        
        ${iconStyle};
      `;

    case 'red':
      return css`
        color: ${({ theme }) => theme.color.white};
        border-color: ${({ theme }) => theme.color.red[500]};
        background-color: ${({ theme }) => theme.color.red[500]};
        
        ${iconStyle};
      `;

    case 'green':
      return css`
        color: ${({ theme }) => theme.color.white};
        border-color: ${({ theme }) => theme.color.green[500]};
        background-color: ${({ theme }) => theme.color.green[500]};

        ${iconStyle};
      `;

    case 'pink':
      return css`
        color: ${({ theme }) => theme.color.white};
        border-color: ${({ theme }) => theme.color.pink[500]};
        background-color: ${({ theme }) => theme.color.pink[500]};
        
        ${iconStyle};
      `;

    default:
      return '';
  }
}