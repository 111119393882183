import styled from 'styled-components';
import { resultBtnStd } from '../styled';

export const Content = styled.div`
  padding: 0 16px 40px;
`;

export const SaleInLotsBtn = styled.button`
  ${resultBtnStd};
  align-items: center;
  grid-template-columns: minmax(0, 1fr) 60px;
`;

export const TextTag = styled.p<{ $color: string; }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 7px;
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.font.caption2};
  font-weight: 400;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 20px;
`;
