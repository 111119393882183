import { StateCreator } from 'zustand';

export const initAveragePrice: StoreAveragePrice.AveragePriceState = {
  name: '',
  list: [],
};

export const createAveragePriceSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreAveragePrice.State
> = set => ({
  ...initAveragePrice,
  resetAveragePrice() {
    set(draft => {
      draft.averagePrice = {
        ...draft.averagePrice,
        ...initAveragePrice
      };
    });
  },
});
