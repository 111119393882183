import styled from 'styled-components';

export const Wrap = styled.div`
  width: 274px;
  padding: 24px 20px 22px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.color.gray[900]};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const TodayBtn = styled.button`
  height: 20px;
  padding: 0 7px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.caption1};
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  border-radius: 20px;
  background-color: transparent;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 4px;
  margin-top: 4px;
  margin-bottom: 16px;
  
  button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  
  > button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    
    &:first-child {
      margin-right: auto;
    }
    &:last-child {
      margin-left: auto;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        > svg {
          path[fill] {
            stroke: ${({ theme }) => theme.color.gray[900]} !important;
          }
        }
      }
    }
  }
`;

export const CurrentDate = styled.div`
  > button,
  > span {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
    font-weight: 700;
  }
  
  > button {
    cursor: pointer;
  }
`;
