function throttle<F extends (...args: any[]) => any>(func: F, wait: number) {
  let throttleCheck: NodeJS.Timeout | null;

  return (...args: Parameters<F>) => {
    if (!throttleCheck) {
      throttleCheck = setTimeout(() => {
        func(...args);
        throttleCheck = null;
      }, wait);
    }
  };
}

export default throttle;
