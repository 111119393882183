import decodeComponent from 'decode-uri-component';
import splitOnFirst from 'split-on-first';
import { includeKeys } from 'filter-obj';

const isNullOrUndefined = (value: any) => value === null || value === undefined;

const strictUriEncode = (string: any) => encodeURIComponent(string).replace(/[!'()*]/g, x => `%${x.charCodeAt(0).toString(16).toUpperCase()}`);

const encodeFragmentIdentifier = Symbol('encodeFragmentIdentifier');

function encoderForArrayFormat(options: any) {
  switch (options.arrayFormat) {
    case 'index': {
      return (key: any) => (result: any, value: any) => {
        const index = result.length;

        if (
          value === undefined
          || (options.skipNull && value === null)
          || (options.skipEmptyString && value === '')
        ) {
          return result;
        }

        if (value === null) {
          return [
            ...result, [encode(key, options), '[', index, ']'].join(''),
          ];
        }

        return [
          ...result,
          [encode(key, options), '[', encode(index, options), ']=', encode(value, options)].join(''),
        ];
      };
    }

    case 'bracket': {
      return (key: any) => (result: any, value: any) => {
        if (
          value === undefined
          || (options.skipNull && value === null)
          || (options.skipEmptyString && value === '')
        ) {
          return result;
        }

        if (value === null) {
          return [
            ...result,
            [encode(key, options), '[]'].join(''),
          ];
        }

        return [
          ...result,
          [encode(key, options), '[]=', encode(value, options)].join(''),
        ];
      };
    }

    case 'colon-list-separator': {
      return (key: any) => (result: any, value: any) => {
        if (
          value === undefined
          || (options.skipNull && value === null)
          || (options.skipEmptyString && value === '')
        ) {
          return result;
        }

        if (value === null) {
          return [
            ...result,
            [encode(key, options), ':list='].join(''),
          ];
        }

        return [
          ...result,
          [encode(key, options), ':list=', encode(value, options)].join(''),
        ];
      };
    }

    case 'comma':
    case 'separator':
    case 'bracket-separator': {
      const keyValueSep = options.arrayFormat === 'bracket-separator'
        ? '[]='
        : '=';

      return (key: any) => (result: any, value: any) => {
        if (
          value === undefined
          || (options.skipNull && value === null)
          || (options.skipEmptyString && value === '')
        ) {
          return result;
        }

        // Translate null to an empty string so that it doesn't serialize as 'null'
        value = value === null ? '' : value;

        if (result.length === 0) {
          return [[encode(key, options), keyValueSep, encode(value, options)].join('')];
        }

        return [[result, encode(value, options)].join(options.arrayFormatSeparator)];
      };
    }

    default: {
      return (key: any) => (result: any, value: any) => {
        if (
          value === undefined
          || (options.skipNull && value === null)
          || (options.skipEmptyString && value === '')
        ) {
          return result;
        }

        if (value === null) {
          return [
            ...result,
            encode(key, options),
          ];
        }

        return [
          ...result,
          [encode(key, options), '=', encode(value, options)].join(''),
        ];
      };
    }
  }
}

function parserForArrayFormat(options: any) {
  let result;

  switch (options.arrayFormat) {
    case 'index': {
      return (key: any, value: any, accumulator: any) => {
        result = /\[(\d*)]$/.exec(key);

        key = key.replace(/\[\d*]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = {};
        }

        accumulator[key][result[1]] = value;
      };
    }

    case 'bracket': {
      return (key: any, value: any, accumulator: any) => {
        result = /(\[])$/.exec(key);
        key = key.replace(/\[]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = [value];
          return;
        }

        accumulator[key] = [...accumulator[key], value];
      };
    }

    case 'colon-list-separator': {
      return (key: any, value: any, accumulator: any) => {
        result = /(:list)$/.exec(key);
        key = key.replace(/:list$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = [value];
          return;
        }

        accumulator[key] = [...accumulator[key], value];
      };
    }

    case 'comma':
    case 'separator': {
      return (key: any, value: any, accumulator: any) => {
        const isArray = typeof value === 'string' && value.includes(options.arrayFormatSeparator);
        const isEncodedArray = (typeof value === 'string' && !isArray && decode(value, options).includes(options.arrayFormatSeparator));
        value = isEncodedArray ? decode(value, options) : value;
        const newValue = isArray || isEncodedArray ? value.split(options.arrayFormatSeparator).map((item: any) => decode(item, options)) : (value === null ? value : decode(value, options));
        accumulator[key] = newValue;
      };
    }

    case 'bracket-separator': {
      return (key: any, value: any, accumulator: any) => {
        const isArray = /(\[])$/.test(key);
        key = key.replace(/\[]$/, '');

        if (!isArray) {
          accumulator[key] = value ? decode(value, options) : value;
          return;
        }

        const arrayValue = value === null
          ? []
          : value.split(options.arrayFormatSeparator).map((item: any) => decode(item, options));

        if (accumulator[key] === undefined) {
          accumulator[key] = arrayValue;
          return;
        }

        accumulator[key] = [...accumulator[key], ...arrayValue];
      };
    }

    default: {
      return (key: any, value: any, accumulator: any) => {
        if (accumulator[key] === undefined) {
          accumulator[key] = value;
          return;
        }

        accumulator[key] = [...[accumulator[key]].flat(), value];
      };
    }
  }
}

function validateArrayFormatSeparator(value: any) {
  if (typeof value !== 'string' || value.length !== 1) {
    throw new TypeError('arrayFormatSeparator must be single character string');
  }
}

function encode(value: any, options: any) {
  if (options.encode) {
    return options.strict ? strictUriEncode(value) : encodeURIComponent(value);
  }

  return value;
}

function decode(value: any, options: any) {
  if (options.decode) {
    return decodeComponent(value);
  }

  return value;
}

function keysSorter(input: any): any {
  if (Array.isArray(input)) {
    return input.sort();
  }

  if (typeof input === 'object') {
    return keysSorter(Object.keys(input))
      .sort((a: any, b: any) => Number(a) - Number(b))
      .map((key: any) => input[key]);
  }

  return input;
}

function removeHash(input: any) {
  const hashStart = input.indexOf('#');
  if (hashStart !== -1) {
    input = input.slice(0, hashStart);
  }

  return input;
}

function getHash(url: any) {
  let hash = '';
  const hashStart = url.indexOf('#');
  if (hashStart !== -1) {
    hash = url.slice(hashStart);
  }

  return hash;
}

function NumberIsNaN(x: any): boolean {
  return typeof x === 'number' && isNaN(x);
}

function parseValue(value: any, options: any) {
  if (options.parseNumbers && !NumberIsNaN(Number(value)) && (typeof value === 'string' && value.trim() !== '')) {
    value = Number(value);
  } else if (options.parseBooleans && value !== null && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')) {
    value = value.toLowerCase() === 'true';
  }

  return value;
}

export function extract(input: any) {
  input = removeHash(input);
  const queryStart = input.indexOf('?');
  if (queryStart === -1) {
    return '';
  }

  return input.slice(queryStart + 1);
}

export function parse(query: any, options?: any) {
  options = {
    decode: true,
    sort: true,
    arrayFormat: 'none',
    arrayFormatSeparator: ',',
    parseNumbers: false,
    parseBooleans: false,
    ...options,
  };

  validateArrayFormatSeparator(options.arrayFormatSeparator);

  const formatter = parserForArrayFormat(options);

  // Create an object with no prototype
  const returnValue = Object.create(null);

  if (typeof query !== 'string') {
    return returnValue;
  }

  query = query.trim().replace(/^[?#&]/, '');

  if (!query) {
    return returnValue;
  }

  for (const parameter of query.split('&')) {
    if (parameter === '') {
      continue;
    }

    const parameter_ = options.decode ? parameter.replace(/\+/g, ' ') : parameter;

    let [key, value] = splitOnFirst(parameter_, '=');

    if (key === undefined) {
      key = parameter_;
    }

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    value = value === undefined ? null : (['comma', 'separator', 'bracket-separator'].includes(options.arrayFormat) ? value : decode(value, options));
    formatter(decode(key, options), value, returnValue);
  }

  for (const [key, value] of Object.entries(returnValue)) {
    if (typeof value === 'object' && value !== null) {
      for (const [key2, value2] of Object.entries(value)) {
        (value as any)[key2] = parseValue(value2, options);
      }
    } else {
      returnValue[key] = parseValue(value, options);
    }
  }

  if (options.sort === false) {
    return returnValue;
  }

  // TODO: Remove the use of `reduce`.
  return (options.sort === true ? Object.keys(returnValue).sort() : Object.keys(returnValue).sort(options.sort)).reduce((result, key) => {
    const value = returnValue[key];
    if (Boolean(value) && typeof value === 'object' && !Array.isArray(value)) {
      // Sort object keys, not values
      result[key] = keysSorter(value);
    } else {
      result[key] = value;
    }

    return result;
  }, Object.create(null));
}

export function stringify(object: any, options?: any) {
  if (!object) {
    return '';
  }

  options = {
    encode: true,
    strict: true,
    arrayFormat: 'none',
    arrayFormatSeparator: ',',
    ...options
  };

  validateArrayFormatSeparator(options.arrayFormatSeparator);

  const shouldFilter = (key: any) => (
    (options.skipNull && isNullOrUndefined(object[key]))
    || (options.skipEmptyString && object[key] === '')
  );

  const formatter = encoderForArrayFormat(options);

  const objectCopy: any = {};

  for (const [key, value] of Object.entries(object)) {
    if (!shouldFilter(key)) {
      objectCopy[key] = value;
    }
  }

  const keys = Object.keys(objectCopy);

  if (options.sort !== false) {
    keys.sort(options.sort);
  }

  return keys.map(key => {
    const value = object[key];

    if (value === undefined) {
      return '';
    }

    if (value === null) {
      return encode(key, options);
    }

    if (Array.isArray(value)) {
      if (value.length === 0 && options.arrayFormat === 'bracket-separator') {
        return encode(key, options) + '[]';
      }

      return value
        .reduce(formatter(key), [])
        .join('&');
    }

    return encode(key, options) + '=' + encode(value, options);
  }).filter(x => x.length > 0).join('&');
}

export function parseUrl(url: any, options?: any) {
  options = {
    decode: true,
    ...options,
  };

  let [url_, hash] = splitOnFirst(url, '#');

  if (url_ === undefined) {
    url_ = url;
  }

  return {
    url: url_?.split('?')?.[0] ?? '',
    query: parse(extract(url), options),
    ...(options && options.parseFragmentIdentifier && hash ? { fragmentIdentifier: decode(hash, options) } : {}),
  };
}

export function stringifyUrl(object: any, options?: any) {
  options = {
    encode: true,
    strict: true,
    [encodeFragmentIdentifier]: true,
    ...options,
  };

  const url = removeHash(object.url).split('?')[0] || '';
  const queryFromUrl = extract(object.url);

  const query = {
    ...parse(queryFromUrl, { sort: false }),
    ...object.query,
  };

  let queryString = stringify(query, options);
  if (queryString) {
    queryString = `?${queryString}`;
  }

  let hash = getHash(object.url);
  if (object.fragmentIdentifier) {
    const urlObjectForFragmentEncode = new URL(url);
    urlObjectForFragmentEncode.hash = object.fragmentIdentifier;
    hash = options[encodeFragmentIdentifier] ? urlObjectForFragmentEncode.hash : `#${object.fragmentIdentifier}`;
  }

  return `${url}${queryString}${hash}`;
}

export function pick(input: any, filter: any, options?: any) {
  options = {
    parseFragmentIdentifier: true,
    [encodeFragmentIdentifier]: false,
    ...options,
  };

  const { url, query, fragmentIdentifier } = parseUrl(input, options);

  return stringifyUrl({
    url,
    query: includeKeys(query, filter),
    fragmentIdentifier,
  }, options);
}

export function exclude(input: any, filter: any, options?: any) {
  const exclusionFilter = Array.isArray(filter) ? (key: any) => !filter.includes(key) : (key: any, value: any) => !filter(key, value);

  return pick(input, exclusionFilter, options);
}