import React from 'react';
import type { FlexGroupProps } from '../types';

import { Container } from './styled';

export function FlexGroupUIComponent({
  contentWidth = '100%',
  groupSpace = '24px',
  children,
  style,
}: FlexGroupProps) {
  return (
    <Container
      contentWidth={contentWidth}
      groupSpace={groupSpace}
      style={style}
    >
      {children}
    </Container>
  );
}

FlexGroupUIComponent.displayName = 'FlexGroup';
