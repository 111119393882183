import { useRef, useState, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useSpringValue, easings } from '@react-spring/web';

import useModalStore from '../../store';

interface Props {
  isOpen: boolean;
}

function useBottomSheet({
  isOpen,
}: Props) {
  const _id = useRef(nanoid());
  const _idx = useRef(-1);

  const [isShow, setShow] = useState(false);

  const modals = useModalStore(store => store.modals);
  const pushModal = useModalStore(store => store.pushModal);
  const popModal = useModalStore(store => store.popModal);

  const y = useSpringValue(100);

  const isActive = useMemo(() => {
    return modals[modals.length - 1] === _id.current;
  }, [modals]);

  useEffect(() => {
    _idx.current = modals.findIndex(modalId => modalId === _id.current);
  }, [modals]);

  useEffect(() => {
    if (isOpen) {
      open();
    }

    return () => {
      if (isOpen) {
        close();
      }
    };
  }, [isOpen]);

  function open() {
    setShow(true);

    y.start(0, {
      config: {
        duration: 300,
        easing: easings.easeInQuart,
      },
    });

    if (_idx.current < 0) {
      pushModal(_id.current);
    }
  }

  function close() {
    if (_idx.current >= 0) {
      y.start(100, {
        config: {
          duration: 300,
          easing: easings.easeOutQuart,
        },
        onRest: () => {
          setShow(false);

          setTimeout(() => {
            popModal(_id.current);
            _idx.current = -1;
          }, 0);
        }
      });
    }
  }

  return {
    id: _id.current,
    idx: _idx.current,
    isShow,
    isActive,
    y,
  };
}

export default useBottomSheet;
