interface ReturnReject {
  status: number;
  code: number | null;
  msg: string;
}

function isServerError(err: unknown): err is ReturnReject {
  return (
    typeof err === 'object' &&
    err !== null &&
    'msg' in err &&
    typeof (err as Record<string, unknown>).msg === 'string'
  );
}

function getErrorMessage(err: unknown) {
  console.log(err);

  if (isServerError(err)) {
    return err;

  } else if (typeof err === 'string') {
    return { msg: err };

  } else {
    return { msg: '에러가 발생하였습니다.' };
  }
}

export default getErrorMessage;
