import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const linkStd = css`
  flex: none;
  padding: 8px 16px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body2};
  font-weight: 400;
  border-radius: 8px;
  user-select: none;
  background-color: #fff;

  &.active {
    color: ${({ theme }) => theme.color.blue[500]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[200]};
  }
`;

export const MenuNavLink = styled(NavLink)`
  ${linkStd};
`;

export const OutLink = styled.a`
  ${linkStd};
`;
