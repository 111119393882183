import { useRef, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useSpring, easings } from '@react-spring/web';

import { useSearchHistoryStore } from '~/store/search-history';
import useSearch from '~/hooks/search/useSearch';

import throttle from '~/utils/throttle';

function useMapSearch() {
  const _search = useRef<HTMLDivElement>(null);
  const _label = useRef<HTMLLabelElement>(null);
  const _input = useRef<HTMLInputElement>(null);

  const [isActive, setActive] = useState(false);
  const [isShowRemove, setShowRemove] = useState(false);

  const {
    searchedKeyword, search, keywords,
    getSearch, resetSearch,
  } = useSearch();

  const history = useSearchHistoryStore(store => store.history);

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', mouseDown, false);

    } else {
      if (_input.current) _input.current.value = '';
      resetSearch();
      document.removeEventListener('mousedown', mouseDown);
    }
  }, [isActive]);

  const { searchX } = useSpring({
    searchX: isActive ? 10 : -30,
    config: {
      duration: 250,
      easing: easings.easeOutCubic,
    },
  });

  const mouseDown = useCallback(e => {
    if (_search.current && !_search.current.contains(e.target)) {
      clickClose();
    }

    if (_label.current && !_label.current.contains(e.target)) {
      setShowRemove(false);
    }
  }, []);

  const changeInput = throttle((e: ChangeEvent<HTMLInputElement>) => {
    if (!_input.current) return;

    if (_input.current.value !== '') {
      getSearch(_input.current.value);

    } else {
      resetSearch();
    }
  }, 400);

  function focusInput() {
    window.scrollTo(0, 0);
    setShowRemove(true);
    setActive(true);
  }

  function clickRemoveKeyword() {
    if (!_input.current) return;

    const nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
    nativeSetter?.call(_input.current, '');
    const ev = new Event('input', { bubbles: true });
    _input.current.dispatchEvent(ev);
    _input.current.focus();
  }

  function clickClose() {
    setActive(false);
  }

  return {
    searchX,
    _search, _label, _input,
    isActive, isShowRemove,
    changeInput, focusInput,
    clickRemoveKeyword,
    clickClose,
    search, keywords, searchedKeyword,
    history,
  };
}

export default useMapSearch;
