import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '~/store';

import ga from '~/utils/ga';

function useMenu() {
  const history = useHistory();

  const {
    isAuth,
    isAgent,
    existRoom,
  } = useSelector(({ user }: RootState) => user);

  function clickMapLink(e: MouseEvent<HTMLAnchorElement>) {
    if (e.currentTarget.className.indexOf('active') > -1) return e.preventDefault();
    ga('GNB', '메뉴클릭', '지도');
  }

  function clickSaleLink() {
    ga('GNB', '메뉴클릭', '분양');
  }

  function clickFavoriteLink(e: MouseEvent<HTMLAnchorElement>) {
    if (e.currentTarget.className.indexOf('active') > -1) return e.preventDefault();
    ga('GNB', '메뉴클릭', '관심목록');
  }

  function clickAgentManageLink(e: MouseEvent<HTMLAnchorElement>) {
    if (e.currentTarget.className.indexOf('active') > -1) return e.preventDefault();
  }

  function clickManageLink(e: MouseEvent<HTMLAnchorElement>) {
    if (!isAuth) {
      e.preventDefault();

      const to = e.currentTarget.href.replace(window.location.origin, '');

      history.push({
        pathname: '/welcome',
        search: `return_url=${encodeURIComponent(to)}`
      });
    }

    ga('GNB', '메뉴클릭', '방내놓기');
  }

  return {
    isAgent, existRoom,
    clickMapLink,
    clickSaleLink,
    clickFavoriteLink,
    clickAgentManageLink,
    clickManageLink,
  };
}

export default useMenu;
