import styled from 'styled-components';
import { a } from '@react-spring/web';

export const BottomSheetContainer = styled.div<{
  $zIndex: number;
}>`
  display: flex;
  align-items: flex-end;
  padding-top: 100px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ $zIndex }) => $zIndex};

  @media only screen and (max-width: 360px) {
    padding-top: 80px;
  }
`;

export const BottomSheetContent = styled(a.div)`
  flex: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 100px);
  max-height: calc((var(--vh, 1vh) * 100) - 100px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #fff;
  position: relative;
  z-index: 1002;

  @media only screen and (max-width: 360px) {
    max-height: calc(100vh - 80px);
    max-height: calc((var(--vh, 1vh) * 100) - 80px);
  }
`;
