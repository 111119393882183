import React, { useRef, forwardRef, ChangeEvent, FocusEvent } from 'react';

import { BaseInputUIComponent } from './base';
import type { NumberInputProps } from './types';

export const NumberInputUIComponent = forwardRef<HTMLInputElement, NumberInputProps>(({
  max,
  value,
  decimal,
  onChange,
  onChangeNative,
  onBlur,
  ...props
}, ref) => {
  const _input = useRef<HTMLInputElement | null>(null);

  const regexDecimal = /[e\-+= ,]/i;
  const regexNumber = /[e\-+= ,.]/i;

  function change(e: ChangeEvent<HTMLInputElement>) {
    const { name, value: tValue } = e.currentTarget;
    let targetValue = tValue;

    // isNaN 이거나 위 패턴에 맞지 않을경우 체크
    if (Number.isNaN(+targetValue) || targetValue.match(decimal !== undefined ? regexDecimal : regexNumber)) return;

    // 최대 자리수를 넘었는지 체크 (숫자의 자릿수로 판단) (숫자의 크기를 판단 X)
    if (value !== undefined && max && targetValue.length > max) return;

    // 숫자
    if (decimal !== undefined) {
      // 최대 숫자 체크
      if (targetValue !== '' && max && +targetValue > max) return;

      // 소수점 자리수 체크
      const regex = new RegExp(`^\\d+(.\\d{0,${decimal}})?$`, 'g');
      if (targetValue !== '' && !regex.test(targetValue)) return;

      // 0이 아니면서 0으로 시작하는 숫자일경우 앞의 0을 제거한다.
      const zeroStartRegex = new RegExp('^0+(\\d)+$');
      if (zeroStartRegex.test(targetValue)) {
        targetValue = (+targetValue).toString();
      }

      // 텍스트인 숫자
    } else {
      // 최대 숫자 자리수 체크
      if (targetValue !== '' && max && targetValue.length > max) return;
    }

    onChangeNative?.(e);
    onChange?.({ name, value: targetValue });
  }

  function blur(e: FocusEvent<HTMLInputElement>) {
    if (_input.current && decimal !== undefined) {
      e.persist();

      const nativeSetter = Object.getOwnPropertyDescriptor(window!.HTMLInputElement.prototype, 'value')?.set;

      // . 으로 끝났을경우 . 제거
      // 0 이 아니면서 0으로 시작하는 숫자 "ex) 012 또는 000012" 와 같은 경우 앞의 0 제거
      const targetValue = e.currentTarget.value === '' ? '' : (+e.currentTarget.value).toString();

      nativeSetter?.call(_input.current, targetValue);

      const ev = new Event('input', { bubbles: true });
      _input.current.dispatchEvent(ev);

      const currentTarget = e.currentTarget;

      setTimeout(() => {
        e.currentTarget = currentTarget;
        onBlur?.(e);
      }, 0);

    } else {
      onBlur?.(e);
    }
  }

  return (
    <BaseInputUIComponent
      ref={node => {
        _input.current = node;

        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      max={max}
      hideMax
      type="text"
      value={value}
      onChange={change}
      onBlur={blur}
      {...props}
    />
  );
});

NumberInputUIComponent.displayName = 'NumberInput';
