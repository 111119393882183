import { m2 } from '~/enum/config';

/**
 * @description m2 -> 평
 * @param {string|number|null} size - 크기
 * @param {string} [initial='']
 */
function m2ToPyeong(size?: number, initial: string = ''): string {
  if (size) return (+(size / m2).toFixed(1)).toFixed(0);
  return initial;
}

export default m2ToPyeong;
