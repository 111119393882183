import React from 'react';

import useMenu from './useMenu';

import {
  MenuContainer,
  MenuNavLink, OutLink,
} from './styled';

function MenuComponent() {
  const {
    isAgent, existRoom,
    clickMapLink,
    clickSaleLink,
    clickFavoriteLink,
    clickAgentManageLink,
    clickManageLink,
  } = useMenu();

  const lastMapType = (localStorage.getItem('last-map-type') ?? 'onetwo') as Map.TPathType;

  return (
    <MenuContainer>
      <MenuNavLink
        to={`/map/${lastMapType}`}
        onClick={clickMapLink}
      >
        <>지도</>
      </MenuNavLink>

      <MenuNavLink
        to="/sales"
        onClick={clickSaleLink}
      >
        <>분양</>
      </MenuNavLink>

      <MenuNavLink
        to="/favorite"
        onClick={clickFavoriteLink}
      >
        <>관심목록</>
      </MenuNavLink>

      {isAgent ? (
        <OutLink
          href="/pro"
          onClick={clickAgentManageLink}
        >
          방내놓기
        </OutLink>
      ) : (
        <MenuNavLink
          to="/manage"
          onClick={clickManageLink}
        >
          <>{existRoom ? '내 방 관리' : '방내놓기'}</>
        </MenuNavLink>
      )}
    </MenuContainer>
  );
}

export default MenuComponent;
