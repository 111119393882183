import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import rootStyle from '~/common/root-style';

export default createGlobalStyle`
  ${reset};
  ${rootStyle};

  * {
    box-sizing: border-box;
  }

  :root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    font-feature-settings: "case", "ss03";
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: contain;
  }

  body {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;

    p {
      &.bold {
        font-weight: 700;
      }

      &.body2 {
        ${({ theme }) => theme.font.body2};
      }

      &.body3 {
        ${({ theme }) => theme.font.body3};
      }

      &.caption1 {
        ${({ theme }) => theme.font.caption1};
      }

      &.gray-900 {
        color: ${({ theme }) => theme.color.gray[900]};
      }

      &.gray-700 {
        color: ${({ theme }) => theme.color.gray[700]};
      }

      &.gray-600 {
        color: ${({ theme }) => theme.color.gray[600]};
      }

      &.red-500 {
        color: ${({ theme }) => theme.color.red[500]};
      }

      &.error-text {
        margin-top: 8px;
        color: ${({ theme }) => theme.color.red[500]};
        ${({ theme }) => theme.font.caption1};
      }
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  #content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 1200px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
  }

  #modal,
  #modal-calendar {
    overflow: hidden;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    [data-simplebar] {
      height: 100%;
    }
  }
`;
