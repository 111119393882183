import { StateCreator } from 'zustand';

export const initSaleInLotsFilter: StoreFilter.SaleInLotsFilter = {
  buildingTypeList: new Set([
    'APT',
    'OFFICETEL',
    'URBAN_HOUSE',
    'KNOWLEDGE_INDUSTRY_CENTER',
  ]),
  stateList: new Set([
    'EXPECTED',
    'ACCEPTING',
    'ACCEPT_COMPLETE',
    'MOVE_IN_EXPECTED',
    'MOVE_IN_RECRUIT',
  ]),
  scheduleStateList: new Set([
    'RECRUITMENT',
    'SPECIAL_SUPPLY',
    'FIRST_PRIORITY',
    'SECOND_PRIORITY',
    'ACCEPTANCE',
    'ANNOUNCEMENT',
    'CONTRACT_TERM',
    'EXPECTED_MOVE_IN_DATE',
    'COMPLETION_PERIOD',
  ]),
  supplyTypeList: new Set([
    'PUBLIC_SALE',
    'PRIVATE_SALE',
    'PUBLIC_RENTAL',
    'PRIVATE_RENTAL',
  ]),
};

export const createSaleInLotsFilterSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreFilter.SaleInLotsFilter & StoreFilter.Actions<StoreFilter.SaleInLotsFilter>
> = (set, get) => ({
  ...initSaleInLotsFilter,
  getFilterJson() {
    const filter = get().filter.saleInLots;

    return ({
      buildingTypeList: [...filter.buildingTypeList],
      stateList: [...filter.stateList],
      scheduleStateList: [...filter.scheduleStateList],
      supplyTypeList: [...filter.supplyTypeList],
    });
  },
  resetTarget: target => {
    set(draft => {
      draft.filter.saleInLots = {
        ...draft.filter.saleInLots,
        [target]: initSaleInLotsFilter[target],
      };
    });
  },
  reset: () => {
    set(draft => {
      draft.filter.saleInLots = {
        ...draft.filter.saleInLots,
        ...initSaleInLotsFilter,
      };

      draft.isListReady = '';
    });
  }
});
