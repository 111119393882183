import React from 'react';

import useCalendar, { datePattern } from './useCalendar';

import type { CalendarProps } from '../types';

import DateContent from './components/date';
import MonthContent from './components/month';
import YearContent from './components/year';

import {
  Wrap,
  TodayBtn,
  Header, CurrentDate,
} from './styled';

export function CalendarUIComponent({
  name,
  activeDate,
  maxDate,
  minDate,
  dateFormat,
  onChange,
}: CalendarProps) {
  let selectDate = '';
  if (activeDate && new RegExp(datePattern).test(activeDate)) {
    selectDate = activeDate.replace(datePattern, '$1-$2-$3');
  }

  const {
    showType,
    year, month,
    days, yearRange, minMax,
    clickToday,
    clickShowYear, clickShowMonth,
    clickYear, clickMonth, clickDate,
    clickPrev, clickNext,
  } = useCalendar({
    name,
    activeDate,
    maxDate,
    minDate,
    dateFormat,
    onChange,
  });

  return (
    <Wrap>
      <TodayBtn onClick={clickToday}>
        today
      </TodayBtn>
      
      <Header>
        <button onClick={clickPrev}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9571 6.25C15.1523 6.44526 15.1523 6.76185 14.9571 6.95711L10.3106 11.6036L14.9571 16.25C15.1523 16.4453 15.1523 16.7618 14.9571 16.9571C14.7618 17.1524 14.4452 17.1524 14.25 16.9571L8.89642 11.6036L14.25 6.25C14.4452 6.05474 14.7618 6.05474 14.9571 6.25Z"
              fill="#CCCCCC"
            />
          </svg>
        </button>

        <CurrentDate>
          <button onClick={clickShowYear}>{year}</button>
          {showType === 'day' && <span>.</span>}
          {showType === 'day' && <button onClick={clickShowMonth}>{month > 9 ? month : `0${month}`}</button>}
        </CurrentDate>

        <button onClick={clickNext}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.04287 16.9571C8.84761 16.7618 8.84761 16.4453 9.04287 16.25L13.6893 11.6036L9.04287 6.95711C8.84761 6.76184 8.84761 6.44526 9.04287 6.25C9.23813 6.05474 9.55471 6.05474 9.74998 6.25L15.1035 11.6036L9.74998 16.9571C9.55471 17.1524 9.23813 17.1524 9.04287 16.9571Z"
              fill="#CCCCCC"
            />
          </svg>
        </button>
      </Header>

      {showType === 'day' ? (
        <DateContent
          selectDate={selectDate}
          days={days}
          onClick={clickDate}
        />
      ) : showType === 'month' ? (
        <MonthContent
          year={year}
          month={month}
          minMax={minMax}
          onClick={clickMonth}
        />
      ) : showType === 'year' && (
        <YearContent
          year={year}
          range={yearRange}
          minMax={minMax}
          onClick={clickYear}
        />
      )}
    </Wrap>
  );
}

CalendarUIComponent.displayName = 'Calendar';
