import isEmpty from '~/utils/valid/is-empty';

function isArray(arr: []) {
  return Array.isArray(arr);
}

function isObject(obj: any) {
  return !Array.isArray(obj) && obj instanceof Object;
}

function checkArray(arr: []) {
  if (arr.length <= 0) return null;

  const result = arr.reduce((r: any[], item) => {
    let data: any = item;

    if (isArray(item)) {
      data = checkArray(data);

    } else if (isObject(item)) {
      data = checkObject(data);
    }

    if (!isEmpty(data)) r.push(data);
    return r;
  }, []);
  
  if (result.length <= 0) return null;
  return result;
}

function checkObject(obj: any) {
  let temp: any = null;
  
  Object.keys(obj).forEach(name => {
    let data = obj[name];

    if (isArray(obj[name])) {
      data = checkArray(data);
    } else if (isObject(obj[name])) {
      data = checkObject(data);
    }

    if (!isEmpty(data)) {
      temp = {
        ...temp,
        [name]: data
      };
    }
  });

  return temp;
}

type TObj = { [P: string]: any }
function deleteBlank(json: TObj) {
  return Object.keys(json).reduce((r: TObj, name) => {
    let data = json[name];

    if (isArray(json[name])) {
      data = checkArray(data);

    } else if (isObject(json[name])) {
      data = checkObject(data);
    }
    
    if (isEmpty(data)) return r;
    if (data === undefined) return r;
    r[name] = data;

    return r;
  }, {});
}

export default deleteBlank;
