import { StateCreator } from 'zustand';

export const initAptFilter: StoreFilter.AptFilter = {
  sellingTypeList: new Set(['MONTHLY_RENT', 'LEASE', 'SELL']),
  tradeRange: {
    min: 0,
    max: 999_999,
  },
  depositRange: {
    min: 0,
    max: 999_999,
  },
  priceRange: {
    min: 0,
    max: 999_999,
  },
  isIncludeMaintenance: false,
  pyeongRange: {
    min: 10,
    max: 70,
  },
  useApprovalDateRange: {
    min: 0,
    max: 999_999,
  },
  dealTypeList: new Set(['AGENT', 'DIRECT']),
  householdNumRange: {
    min: 0,
    max: 999_999,
  },
  parkingNumRange: {
    min: 0,
    max: 999_999,
  },
  isShortLease: false,
  hasTakeTenant: false,
  roomCount: '',
};

export const createAptFilterSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreFilter.AptFilter & StoreFilter.Actions<StoreFilter.AptFilter>
> = (set, get) => ({
  ...initAptFilter,
  getFilterJson() {
    const filter = get().filter.apt;

    return {
      sellingTypeList: [...filter.sellingTypeList],
      tradeRange: filter.tradeRange,
      depositRange: filter.depositRange,
      priceRange: filter.priceRange,
      isIncludeMaintenance: filter.isIncludeMaintenance,
      pyeongRange: filter.pyeongRange,
      useApprovalDateRange: filter.useApprovalDateRange,
      dealTypeList: [...filter.dealTypeList],
      householdNumRange: filter.householdNumRange,
      parkingNumRange: filter.parkingNumRange,
      isShortLease: filter.isShortLease,
      hasTakeTenant: filter.hasTakeTenant,
      roomCount: filter.roomCount,
    };
  },
  resetTarget: target => {
    set(draft => {
      draft.filter.apt = {
        ...draft.filter.apt,
        [target]: initAptFilter[target],
      };
    });
  },
  reset: () => {
    set(draft => {
      draft.filter.apt = {
        ...draft.filter.apt,
        ...initAptFilter,
      };

      if (draft.isListReady !== 'drawing') {
        draft.isListReady = '';
      }
    });
  }
});
