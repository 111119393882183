/**
 * 금액을 콤마를 사용한 표기법으로 바꿔준다.
 * 10000 -> 10,000
 * @param {number|string} price 금액
 */
function priceComma(price: number | '' = 0): string {
  return price?.toString().replace(/\d(?=(?:\d{3})+(?:\.|$))/g, $0 => $0 + ',') ?? 0;
}

export default priceComma;
