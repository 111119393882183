/**
 * @description 해당 객체에 raw type 을 확인하는 함수
 * @param {*} value - 확인이 필요한 객체
 * @return {string} String | Number | Null | Undefined | Symbol | Function | Array | Object
 * */
function rawType(value: any): string {
  return Object.prototype.toString.call(value).slice(8, -1);
}

export default rawType;
