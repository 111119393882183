import { useState, useMemo } from 'react';
import { produce } from 'immer';
import { getYear, getMonth, isBefore, isAfter, format } from 'date-fns/esm';
import { getDateOfMonth, DateItem } from '~/utils/get-date-of-month';

import type { CalendarProps } from '../types';

type TShowType = 'day' | 'month' | 'year';

interface Info {
  year: number;
  month: number;
}

export interface MinMax {
  maxYear: number;
  maxMonth: number;
  minYear: number;
  minMonth: number;
}

export interface YearRange {
  count: number;
  startYear: number;
}

const YEAR_COUNT = 12;
const MAX_DATE = '3000-12-31';
const MIN_DATE = '1900-01-01';

export const datePattern = /^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g;

function useCalendar({
  name,
  activeDate = '',
  maxDate = MAX_DATE,
  minDate = MIN_DATE,
  dateFormat = 'yyyy-MM-dd',
  onChange = () => {},
}: CalendarProps) {
  let currentDate = new Date();
  if (activeDate && new RegExp(datePattern).test(activeDate)) {
    const d = activeDate.replace(datePattern, '$1-$2-$3');
    currentDate = new Date(d);
  }

  if (new RegExp(datePattern).test(minDate)) {
    minDate = minDate.replace(datePattern, '$1-$2-$3');
  } else {
    console.error('invalid minDate', minDate);
  }

  if (new RegExp(datePattern).test(maxDate)) {
    maxDate = maxDate.replace(datePattern, '$1-$2-$3');
  } else {
    console.error('invalid maxDate', maxDate);
  }

  const year = getYear(currentDate);
  const month = getMonth(currentDate);

  const maxYear = getYear(new Date(maxDate));
  const minYear = getYear(new Date(minDate));
  const maxMonth = getMonth(new Date(maxDate));
  const minMonth = getMonth(new Date(minDate));

  const [showType, setShowType] = useState<TShowType>('day');

  const [info, setInfo] = useState<Info>({ year, month });
  const [minMax] = useState<MinMax>({
    maxYear,
    maxMonth,
    minYear,
    minMonth,
  });
  const [yearRange, setYearRange] = useState<YearRange>({
    count: YEAR_COUNT,
    startYear: Math.floor(year / YEAR_COUNT) * YEAR_COUNT,
  });

  function clickToday() {
    const today = new Date();
    const year = getYear(today);
    const month = getMonth(today);

    setInfo({ year, month });
    setShowType('day');
  }

  function clickShowYear() {
    if (showType === 'year') return;
    setShowType('year');
  }

  function clickShowMonth() {
    if (showType === 'month') return;
    setShowType('month');
  }

  function clickYear(year: number) {
    setInfo(prev => ({
      year,
      month: prev.month,
    }));

    setShowType('month');
  }

  function clickMonth(month: number) {
    setInfo(prev => ({
      year: prev.year,
      month,
    }));

    setShowType('day');
  }

  function clickDate(date: Date) {
    const d = format(date, dateFormat);
    onChange({ name, value: d });
  }

  function clickPrev() {
    if (showType === 'day') {
      moveDateView('prev');
    } else if (showType === 'month') {
      moveMonthView('prev');
    } else if (showType === 'year') {
      moveYearView('prev');
    }
  }

  // 화살표 버튼 클릭 이벤트
  function clickNext() {
    if (showType === 'day') {
      moveDateView('next');
    } else if (showType === 'month') {
      moveMonthView('next');
    } else if (showType === 'year') {
      moveYearView('next');
    }
  }

  function moveDateView(type: 'prev' | 'next') {
    const { minYear, minMonth, maxYear, maxMonth } = minMax;

    const newDate = produce(info, draft => {
      if (type === 'prev') {
        if (info.month <= 0) {
          draft.year -= 1;
          draft.month = 11;

        } else {
          draft.month -= 1;
        }

      } else if (type === 'next') {
        if (info.month >= 11) {
          draft.year += 1;
          draft.month = 0;

        } else {
          draft.month += 1;
        }
      }
    });

    const max = new Date(maxYear, maxMonth);
    const min = new Date(minYear, minMonth);
    const current = new Date(newDate.year, newDate.month);

    if (type === 'prev' && isBefore(current, min)) return;
    if (type === 'next' && isAfter(current, max)) return;

    setInfo(newDate);
  }

  function moveMonthView(type: 'prev' | 'next') {
    const { minYear, maxYear } = minMax;

    const newDate = produce(info, draft => {
      if (type === 'prev') {
        if (info.year <= minYear) {
          draft.year = minYear;

        } else {
          draft.year -= 1;
        }

      } else if (type === 'next') {
        if (info.year >= maxYear) {
          draft.year = maxYear;
        } else {
          draft.year += 1;
        }
      }
    });

    setInfo(newDate);
  }

  function moveYearView(type: 'prev' | 'next') {
    const { minYear, maxYear } = minMax;
    const { startYear, count } = yearRange;

    const nextYearRange = produce(yearRange, draft => {
      if (type === 'prev') {
        if (startYear > minYear) {
          draft.startYear -= YEAR_COUNT;
        }

      } else if (type === 'next') {
        if (startYear + count < maxYear) {
          draft.startYear += YEAR_COUNT;
        }
      }
    });

    setYearRange(nextYearRange);
  }

  const days: DateItem[] = useMemo(() => {
    const { calendarDays } = getDateOfMonth({
      year: info.year,
      month: info.month,
      maxDate,
      minDate,
    });
    return calendarDays;
  }, [info.year, info.month, maxDate, minDate]);

  return {
    showType,
    year: info.year,
    month: info.month + 1,
    days, yearRange, minMax,
    clickToday,
    clickShowYear, clickShowMonth,
    clickYear, clickMonth, clickDate,
    clickPrev, clickNext,
  };
}

export default useCalendar;
