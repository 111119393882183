import React from 'react';
import useDatePicker from './useDatePicker';

import type { DatePickerProps } from '../types';

import { NumberInputUIComponent } from '../../input/number-input';
import { CalendarUIComponent } from '../calendar';

import {
  Picker,
  CalendarWrap,
} from './styled';

export function DatePickerUIComponent({
  contentWidth,
  placeholder,
  activeDate = '',
  maxDate,
  minDate,
  dateDivider = '.',
  name,
  onChange,
  disabled,
}: DatePickerProps) {
  const {
    _wrap,
    isOpen,
    inputDate,
    selectDate,
    handleCalendar,
    changeInputDate,
    focusInputDate,
    blurInputDate,
    selectCalendarDate,
  } = useDatePicker({
    activeDate,
    maxDate,
    minDate,
    dateDivider,
    name,
    onChange,
  });

  return (
    <Picker ref={_wrap}>
      <NumberInputUIComponent
        contentWidth={contentWidth}
        rightIcon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99194 3.91012C7.94961 3.67688 7.74546 3.5 7.5 3.5C7.22386 3.5 7 3.72386 7 4V4.9999H5C4.44772 4.9999 4 5.44762 4 5.9999V18.9999C4 19.5522 4.44772 19.9999 5 19.9999H19C19.5523 19.9999 20 19.5522 20 18.9999V5.9999C20 5.44762 19.5523 4.9999 19 4.9999H17V4L16.9919 3.91012C16.9496 3.67688 16.7455 3.5 16.5 3.5C16.2239 3.5 16 3.72386 16 4V4.9999H8V4L7.99194 3.91012ZM16 7V5.9999H8V7C8 7.27614 7.77614 7.5 7.5 7.5C7.25454 7.5 7.05039 7.32312 7.00806 7.08988L7 7V5.9999H5V8.5H19V5.9999H17V7C17 7.27614 16.7761 7.5 16.5 7.5C16.2545 7.5 16.0504 7.32312 16.0081 7.08988L16 7ZM19 9.5H5V18.9999H19V9.5ZM8.5 12.9999H9.501V11.9999H8.5V12.9999ZM12.5 12.9999H11.5V11.9999H12.5V12.9999ZM14.5 12.9999H15.5V11.9999H14.5V12.9999ZM9.501 15.9999H8.5V14.9999H9.501V15.9999ZM11.5 15.9999H12.5V14.9999H11.5V15.9999ZM15.5 15.9999H14.5V14.9999H15.5V15.9999Z"
              fill="#222222"
            />
          </svg>
        )}
        onRightIconClick={handleCalendar}
        value={inputDate}
        onChange={changeInputDate}
        onFocus={focusInputDate}
        onBlur={blurInputDate}
        placeholder={placeholder}
        disabled={disabled}
      />
      {isOpen && (
        <CalendarWrap>
          <CalendarUIComponent
            name={name}
            activeDate={selectDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={selectCalendarDate}
          />
        </CalendarWrap>
      )}
    </Picker>
  );
}

DatePickerUIComponent.displayName = 'DatePicker';
