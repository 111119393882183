import React, { useRef, useEffect } from 'react';
import { useSpringValue, easings } from '@react-spring/web';

import type { ReactNode } from 'react';

import { useToastStore } from '../../store';

import Timer from '../../utils/timer';

import { MessageWrap, Message } from './styled';

interface Props {
  message: ReactNode | ReactNode[];
  timeout: number;
}

function ToastMessageComponent({
  message,
  timeout,
}: Props) {
  const _timer = useRef(new Timer(remove, timeout));
  const _mount = useRef(false);

  const updateNum = useToastStore(store => store.updateNum);
  const removeMessage = useToastStore(store => store.removeMessage);

  useEffect(() => {
    _timer.current.resume();

    if (!_mount.current) {
      opacity.start(1);
      _mount.current = true;
    }

    return () => {
      _timer.current.clear();
    };
  }, [updateNum]);

  const opacity = useSpringValue(0, {
    config: {
      duration: 300,
      easing: easings.easeOutQuart,
    },
    onRest: () => {
      if (!_mount.current) {
        removeMessage();
      }
    }
  });

  function remove() {
    _mount.current = false;
    opacity.start(0);
  }

  return (
    <MessageWrap>
      <Message onClick={remove} style={{ opacity }}>
        {message}
      </Message>
    </MessageWrap>
  );
}

export default ToastMessageComponent;
