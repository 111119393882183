import type { TChipType, TChipColor } from '../../types';

import { getDarkColor } from './dark-color';
import { getLightColor } from './light-color';
import { getLineColor } from './line-color';

function getChipStyle(type: TChipType, color: TChipColor) {
  switch (type) {
    case 'dark':
      return getDarkColor(color);

    case 'light':
      return getLightColor(color);

    case 'line':
      return getLineColor(color);

    default:
      return '';
  }
}

export default getChipStyle;
