import { StateCreator } from 'zustand';

export const initOfficetelFilter: StoreFilter.OfficetelFilter = {
  sellingTypeList: new Set(['MONTHLY_RENT', 'LEASE', 'SELL']),
  tradeRange: {
    min: 0,
    max: 999_999,
  },
  depositRange: {
    min: 0,
    max: 999_999,
  },
  priceRange: {
    min: 0,
    max: 999_999,
  },
  isIncludeMaintenance: false,
  pyeongRange: {
    min: 10,
    max: 70,
  },
  useApprovalDateRange: {
    min: 0,
    max: 999_999,
  },
  dealTypeList: new Set(['AGENT', 'DIRECT']),
  parkingNumRange: {
    min: 0,
    max: 999_999,
  },
  canParking: false,
  isShortLease: false,
  hasElevator: false,
  hasPano: false,
  roomCount: '',
};

export const createOfficetelFilterSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreFilter.OfficetelFilter & StoreFilter.Actions<StoreFilter.OfficetelFilter>
> = (set, get) => ({
  ...initOfficetelFilter,
  getFilterJson() {
    const filter = get().filter.officetel;

    return {
      sellingTypeList: [...filter.sellingTypeList],
      tradeRange: filter.tradeRange,
      depositRange: filter.depositRange,
      priceRange: filter.priceRange,
      isIncludeMaintenance: filter.isIncludeMaintenance,
      pyeongRange: filter.pyeongRange,
      useApprovalDateRange: filter.useApprovalDateRange,
      dealTypeList: [...filter.dealTypeList],
      parkingNumRange: filter.parkingNumRange,
      canParking: filter.canParking,
      isShortLease: filter.isShortLease,
      hasElevator: filter.hasElevator,
      hasPano: filter.hasPano,
      roomCount: filter.roomCount,
    };
  },
  resetTarget: target => {
    set(draft => {
      draft.filter.officetel = {
        ...draft.filter.officetel,
        [target]: initOfficetelFilter[target],
      };
    });
  },
  reset: () => {
    set(draft => {
      draft.filter.officetel = {
        ...draft.filter.officetel,
        ...initOfficetelFilter,
      };

      if (draft.isListReady !== 'drawing') {
        draft.isListReady = '';
      }
    });
  }
});
