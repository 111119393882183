import isEmpty from './is-empty';
/**
 * @description 유효성 검사 이메일편
 * https://station-3.atlassian.net/wiki/spaces/PDEV/pages/821002817
 */
function checkEmail(email: string = ''): string | false {
  if (isEmpty(email)) return '이메일을 입력해주세요.';

  const first = email.substring(0,1);
  if (
    !/[0-9a-z\-_.]*[0-9a-z\-_]+@[0-9a-z\-_.]+(\.[0-9a-z\-_]{2,3})$/.test(email) ||
    first === '.' ||
    /[A-Z]/.test(first)
  ) {
    return '이메일 형식을 확인해주세요.';
  }

  return false;
}

export default checkEmail;
