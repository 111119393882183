import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { proUrl } from '~/enum/config';
import ga from '~/utils/ga';

function useHeader() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const lastIdx = location.search && location.search.lastIndexOf('?return_url');
    const multipleReturn = lastIdx > 0;
    const search = multipleReturn ? location.search.substring(+lastIdx, location.search.length) : '';
    const returnUrl = search ? location.pathname + decodeURIComponent(search) : location.pathname + location.search;

    if (multipleReturn) {
      history.replace(returnUrl);
    }
  }, [location]);

  function clickLogo() {
    ga('GNB', '메뉴클릭', '다방로고');
  }

  function clickLogin() {
    if (location.pathname === '/welcome') return;

    if (location.pathname.includes('/welcome')) {
      history.push({
        pathname: '/welcome',
        search: location.search,
      });

    } else {
      ga('GNB', '메뉴클릭', '로그인');

      history.push({
        pathname: '/welcome',
        search: `return_url=${encodeURIComponent(location.pathname + location.search)}`,
      });
    }
  }

  function clickJoinPro() {
    ga('GNB', '메뉴클릭', '중개사가입문의');
    window.open(`${proUrl}/join-consult`, '_blank');
  }

  return {
    clickLogo,
    clickLogin,
    clickJoinPro,
  };
}

export default useHeader;
