import { useMemo, useRef, useState, useEffect } from 'react';
import { format, isAfter, isBefore, isExists } from 'date-fns';

import type { S3ChangeEvent } from '~/atoms2';

interface Props {
  activeDate: string;
  maxDate?: string;
  minDate?: string;
  dateDivider: string;
  name: string;
  onChange?: ({ name, value }: S3ChangeEvent) => void;
}

const dateRegex = /^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g;

function useDatePicker({
  activeDate,
  maxDate,
  minDate,
  dateDivider,
  name,
  onChange,
}: Props) {
  const dateFormat = useMemo(() => `yyyy${dateDivider}MM${dateDivider}dd`, [dateDivider]);

  const _wrap = useRef<HTMLDivElement>(null);
  const _initDate = useRef<string>('');

  const [isOpen, setOpen] = useState(false);
  const [inputDate, setInputDate] = useState('');
  const [selectDate, setSelectDate] = useState('');

  if (minDate) {
    if (new RegExp(/^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g).test(minDate)) {
      minDate = minDate.replace(/^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g, '$1-$2-$3');
    } else {
      console.error('invalid minDate', minDate);
    }
  }

  if (maxDate) {
    if (new RegExp(/^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g).test(maxDate)) {
      maxDate = maxDate.replace(/^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g, '$1-$2-$3');
    } else {
      console.error('invalid maxDate', maxDate);
    }
  }

  useEffect(() => {
    let initDate = '';

    if (activeDate !== '' && new RegExp(dateRegex).test(activeDate)) {
      const nextDate = activeDate.replace(dateRegex, `$1-$2-$3`);
      initDate = format(new Date(nextDate), dateFormat);
    }

    _initDate.current = initDate;
    setInputDate(initDate);
    setSelectDate(initDate);

  }, [activeDate]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', mouseDown, false);
      document.addEventListener('keydown', keyDown, false);
    } else {
      document.removeEventListener('mousedown', mouseDown);
      document.removeEventListener('keydown', keyDown);
    }
  }, [isOpen]);

  function mouseDown(e: MouseEvent) {
    if (_wrap.current && !_wrap.current.contains(e.target as Node)) {
      setOpen(false);
    }
  }

  function keyDown(e: KeyboardEvent) {
    if (e.key === 'Escape' || e.key === 'Tab') {
      setOpen(false);
    }
  }

  function handleCalendar() {
    setOpen(prev => !prev);
  }

  function changeInputDate({ value }: S3ChangeEvent) {
    if (value.length > 8) return;
    setInputDate(value);
  }

  function focusInputDate() {
    setOpen(false);
    const date = inputDate.replace(dateRegex, `$1$2$3`);
    setInputDate(date);
  }

  function blurInputDate() {
    if (inputDate === '') {
      selectCalendarDate({ name, value: '' });
      return;
    }

    const dateRegex = /^(\d{4})[- ]?(\d{2})[- ]?(\d{2})$/g;

    if (new RegExp(dateRegex).test(inputDate)) {
      const nextDateFormat = inputDate.replace(dateRegex, `$1-$2-$3`);

      if (checkDateExists(nextDateFormat)) {
        if (maxDate && isAfter(new Date(nextDateFormat), new Date(maxDate))) {
          setInputDate('');
          selectCalendarDate({ name, value: '' });

        } else if (minDate && isBefore(new Date(nextDateFormat), new Date(minDate))) {
          setInputDate('');
          selectCalendarDate({ name, value: '' });

        } else {
          selectCalendarDate({ name, value: nextDateFormat });
          setInputDate(format(new Date(nextDateFormat), dateFormat));
        }

      } else {
        setInputDate(_initDate.current);
      }

    } else {
      setInputDate(_initDate.current);
    }
  }

  function checkDateExists(dateFormat: string) {
    const [year, month, date]: string[] = dateFormat.split('-');
    return isExists(+year, +month - 1, +date);
  }

  function selectCalendarDate({ name, value }: S3ChangeEvent) {
    onChange?.({ name, value });
    setOpen(false);
  }

  return {
    _wrap,
    isOpen,
    inputDate,
    selectDate,
    handleCalendar,
    changeInputDate,
    focusInputDate,
    blurInputDate,
    selectCalendarDate,
  };
}

export default useDatePicker;
