import styled from 'styled-components';

export const WebContainer = styled.div<{ $isShow: boolean; }>`
  display: ${({ $isShow }) => $isShow ? 'block' : 'none'};
  position: fixed;
  z-index: 9999;

  @media only screen and (min-width: 601px) {
    top: 40px;
    right: 40px;
  }
  
  @media only screen and (max-width: 600px) {
    top: 20px;
    right: 20px;
  }
`;

export const MobileContainer = styled.div<{ $isShow: boolean; }>`
  display: ${({ $isShow }) => $isShow ? 'block' : 'none'};
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 64px;
  z-index: 9999;
`;
