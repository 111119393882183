class colorRGB {
  private readonly r: number;
  private readonly g: number;
  private readonly b: number;
  
  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  getColor(a?: number): string {
    if (a || a === 0) {
      return `rgba(${this.r}, ${this.g}, ${this.b}, ${a})`;
    }
    
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }
}

export default colorRGB;
