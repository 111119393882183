import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Name = styled.div`
  margin-left: 8px;
  min-width: 80px;
  border: 0;
  position: relative;
  user-select: none;
  cursor: pointer;
  
  > p {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3};
    
    &:hover {
      color: ${({ theme }) => theme.color.gray[900]};
    }
  }
`;

export const Box = styled.div`
  width: 240px;
  position: absolute;
  top: calc(100% + 20px);
  right: 10px;
  z-index: 100;
`;

export const Arrow = styled.div`
  position: absolute;
  top: calc(100% + 13px);
  left: calc(50% - 10px);
  transform: translateX(-50%);
  z-index: 101;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[500]};
    border-left: 1px solid ${({ theme }) => theme.color.gray[500]};
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: #fff;
    transform: translateY(2px) rotate(45deg);
  }
`;

export const UserMenu = styled.nav`
  border: 1px solid ${({ theme }) => theme.color.gray[500]};
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 15px 18px -10px rgba(0, 0, 0, 0.25);
`;

const menuStd = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
`;

export const UserLink = styled(Link)`
  ${menuStd};
  height: 55px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
`;

export const AgentLink = styled.a`
  ${menuStd};
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
`;

export const Logout = styled.button`
  ${menuStd};
  height: 55px;
  border: 0;
  background-color: transparent;

  > svg {
    margin-left: auto;
  }
`;
