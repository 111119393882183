import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 's3Pack__group',
})<{ contentWidth: string; }>`
  flex: none;
  width: ${({ contentWidth }) => contentWidth};
`;

export const Content = styled.div`
  flex: none;
`;
