import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useSearch from './useSearch';

import {
  instagramUrl,
  facebookUrl,
  naverBlogUrl,
  naverPostUrl,
  youtubeUrl,
  signUrl,
} from '~/enum/config';
import scrollToTop from '~/utils/scroll-to-top';

import SalesAdInquiry from './components/popup/sales-ad-inquiry';

import instagram from './images/icon-instagram.png';
import facebook from './images/icon-facebook.png';
import naverBlog from './images/icon-blog.png';
import naverPost from './images/icon-post.png';
import youtube from './images/icon-youtube.png';
import { ReactComponent as SearchIcon } from './images/search.svg';
import {
  Footer,
  TopWrap, TermsWrap, List, TermLink,
  SearchForm, SearchText, SearchInput, SearchBtn, GoTop,
  Content,
  InfoWrap, Info, BtnWrap, CopyRight,
  RightWrap, SocialWrap, SocialIcon,
} from './styled';

function FooterComponent() {
  const {
    keyword,
    onChange,
    onSubmit,
    checkIsSamePath,
  } = useSearch();

  return useMemo(() => {
    return (
      <Footer>
        <div>
          <TopWrap>
            <TermsWrap>
              <List>
                <li>
                  <TermLink href="https://www.station3.co.kr/">
                    회사소개
                  </TermLink>
                </li>
                <li>
                  <TermLink href="https://main.d3qjzrynlab66o.amplifyapp.com/terms/common/terms-of-service">
                    이용약관
                  </TermLink>
                </li>
                <li>
                  <TermLink highlight={true} href="https://main.d3qjzrynlab66o.amplifyapp.com/terms/dabang/collecting-use-personal">
                    개인정보처리방침
                  </TermLink>
                </li>
                <li>
                  <TermLink href="https://main.d3qjzrynlab66o.amplifyapp.com/terms/common/rule-of-room-management">
                    매물관리규정
                  </TermLink>
                </li>
                {/* <li>
                  <TermLink href="https://pro.dabangapp.com/terms/5">
                    자동저장서비스
                  </TermLink>
                </li> */}
                {/* <li>
                  <TermLink href="https://www.station3.co.kr/help-line">
                    헬프라인(신고센터)
                  </TermLink>
                </li> */}
              </List>
            </TermsWrap>

            <SearchForm onSubmit={onSubmit}>
              <label>
                <SearchText>매물번호 조회</SearchText>
                <SearchInput value={keyword} onChange={onChange} />
              </label>

              <SearchBtn type="submit">
                <SearchIcon width="16" height="16" />
              </SearchBtn>
            </SearchForm>

            <GoTop onClick={scrollToTop}>
              <svg width="11" height="7" viewBox="0 0 11 7" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 0L0 7H11L5.5 0Z" fill="#D2D6DA" />
              </svg>
              <p>TOP</p>
            </GoTop>
          </TopWrap>

          <Content>
            <InfoWrap>
              <Info>
                <li>{'(주)스테이션3'}</li>
                <li>{'대표 : 유형석'}</li>
                <li>{'사업자 번호: 220-88-59156     통신판매업신고번호 : 제2013-서울 강남-02884호'}</li>
                <li>{'주소 : 서울시 서초구 서초대로 301 동익 성봉빌딩 10층 (주)스테이션3'}</li>
                <li />
                <li>{'고객센터 : 02-1899-6840 | 평일 10:00 ~ 18:30, 점심시간 : 12:30 ~ 13:30 (토·일요일, 공휴일 휴무)'}</li>
                <li>{'팩스 : 02-554-9774     프로모션/사업 제휴문의 : biz@station3.co.kr     배너/분양 광고문의 : ad@station3.co.kr'}</li>
              </Info>

              <BtnWrap>
                <a
                  href={`${signUrl}/contract-inquiry/lessor`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  비대면계약 조회
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle opacity="0.2" cx="7" cy="7" r="7" fill="#fff" />
                    <path d="M4.34375 9.34375L8.93994 4.74756C9.13521 4.55229 9.45179 4.55229 9.64705 4.74756C9.84231 4.94282 9.84231 5.2594 9.64705 5.45466L5.05086 10.0509L4.34375 9.34375Z" fill="#fff" />
                    <rect x="6.32812" y="4.59961" width="3" height="1" fill="#fff" />
                    <rect x="8.78906" y="8.08496" width="3" height="1" transform="rotate(-90 8.78906 8.08496)" fill="#fff" />
                  </svg>
                </a>

                <Link to="/service/faq" onClick={checkIsSamePath}>
                  자주묻는 질문
                </Link>

                <Link to="/service/inquiry" onClick={checkIsSamePath}>
                  1:1문의
                </Link>

                <SalesAdInquiry />
              </BtnWrap>

              <CopyRight>Station3, Inc. All rights reserved.</CopyRight>
            </InfoWrap>

            <RightWrap>
              <SocialWrap>
                <SocialIcon imgUrl={instagram} href={instagramUrl} />
                <SocialIcon imgUrl={facebook} href={facebookUrl} />
                <SocialIcon imgUrl={naverBlog} href={naverBlogUrl} />
                <SocialIcon imgUrl={naverPost} href={naverPostUrl} />
                <SocialIcon imgUrl={youtube} href={youtubeUrl} />
              </SocialWrap>
            </RightWrap>
          </Content>
        </div>
      </Footer>
    );
  }, [keyword, checkIsSamePath]);
}

export default FooterComponent;
