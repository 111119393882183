import { useRef, useEffect, MutableRefObject } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, Method, AxiosError } from 'axios';
import Cookie from 'js-cookie';
import qs from '~/utils/query-string';
import camelcaseKeys from 'camelcase-keys';

import deleteBlank from '~/utils/delete-blank';

let from: string | null = null;

type THeader = { [name: string]: any; }
type TParams = { [propName: string]: any; } | string;

interface UseApiCall {
  get: (url: string, params?: TParams) => any;
  post: (url: string, data?: TParams) => any;
  put: (url: string, data?: TParams) => any;
}

/**
 * @deprecated
 * 모든 api 가 v5 버전으로 넘어갈경우 해당 hook은 삭제될 예정
 * - /api/v5 버전은 ~/utils/fetch 를 이용해야한다.
 * - /api/3 버전은 해당 hook 이용해야한다.
 */
function useApiCall(): UseApiCall {
  const _apiCall: MutableRefObject<AxiosInstance | null> = useRef(null);
  const _source = useRef(axios.CancelToken.source());

  useEffect(() => {
    let apiCall = axios.create({
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*',
        Expires: -1,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      cancelToken: _source.current.token,
      paramsSerializer: {
        serialize: params => {
          if (window.location.pathname.indexOf('/mobile') > -1) {
            const authKey = Cookie.get('authKey');
            if (authKey) {
              params.authKey = authKey;
            }
          }

          return qs.stringify(params);
        }
      },
    });

    apiCall.interceptors.request.use(reqConfig);
    apiCall.interceptors.response.use(res => res, handleError);

    _apiCall.current = apiCall;

    return () => {
      _source.current.cancel();
    };
  }, []);

  function handleError(error: AxiosError | any) {
    let err;

    if (axios.isCancel(error)) {
      console.log('API 요청이 취소되었습니다.', from, error);
      const f = from;
      from = null;
      return Promise.reject({ msg: '', from: f });
    }

    try {
      err = error.response.data;
    } catch (e) {
      err = error;
    }

    switch (error.response?.status) {
      case 302:
        err = { msg: '로그인이 필요합니다.' };
        break;

      case 500:
        if (typeof err === 'string') {
          err = { msg: err };
        } else if (err?.message) {
          err = { msg: err?.message ?? '서버에러가 발생하였습니다.' };
        } else if (err?.msg) {
          err = { ...err };
        } else {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
        break;

      default:
        if (!err?.msg) {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
    }

    return Promise.reject(err);
  }

  function reqConfig(config: AxiosRequestConfig | any) {
    const WEB_VERSION = 1;
    const APP_VERSION = '3.0.1';
    let CALL_TYPE = 'web';

    if (window.location.pathname.indexOf('/mobile') > -1) {
      CALL_TYPE = 'mobileweb';
    }

    if (['post', 'put', 'patch'].includes(config.method as Method)) {
      config.data.api_version = APP_VERSION;
      config.data.call_type = CALL_TYPE;
      config.data.version = WEB_VERSION;

      config.data = deleteBlank(config.data);

    } else if (config.method === 'get') {
      config.params.api_version = APP_VERSION;
      config.params.call_type = CALL_TYPE;
      config.params.version = WEB_VERSION;

      config.params = deleteBlank(config.params);
    }

    return config;
  }

  function checkToken() {
    let headers: THeader = {};
    const safeAuthConfirm = Cookie.get('safeAuthConfirm');

    if (safeAuthConfirm) {
      headers['Safe-Auth-Confirm'] = safeAuthConfirm;
    }

    return headers;
  }

  /**
   * @description GET Method 요청
   * @param {string} url - 주소
   * @param {Object} params - query params 값
   * @return {Object|string}
   */
  function get(url: string, params = {}) {
    from = url;
    return (_apiCall.current as AxiosInstance)
      .request({
        method: 'GET',
        url,
        params,
        headers: checkToken(),
        responseType: 'json',
      })
      .then(response => {
        from = null;
        return Promise.resolve(camelcaseKeys(response.data, { deep: true }));
      });
  }

  /**
   * @description POST Method 요청
   * @param {string} url - 주소
   * @param {Object} data - body 값
   * @return {Object|string}
   */
  function post(url: string, data = {}) {
    from = url;
    return (_apiCall.current as AxiosInstance)
      .request({
        method: 'POST',
        url,
        data,
        headers: checkToken(),
        responseType: 'json',
      })
      .then(response => {
        from = null;
        return Promise.resolve(camelcaseKeys(response.data, { deep: true }));
      });
  }

  /**
   * @description POST Method 요청
   * @param {string} url - 주소
   * @param {Object} data - body 값
   * @return {Object|string}
   */
  function put(url: string, data = {}) {
    from = url;
    return (_apiCall.current as AxiosInstance)
      .request({
        method: 'PUT',
        url,
        data,
        responseType: 'json',
      })
      .then(response => {
        from = null;
        return Promise.resolve(camelcaseKeys(response.data, { deep: true }));
      });
  }

  return { get, post, put };
}

export default useApiCall;
