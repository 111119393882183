export default {
  isAuth: false,
  isLoading: true,

  isAgent: false,                       // 중개사 계정인지 여부
  isSafeAuth: false,                    // 본인인증 여부
  isRoomMessenger: false,               // 간편문의 이력 여부

  userIdx: null,                        // 유저번호

  authType: 'NONE',                         // 인증 타입 (NONE, PHONE_AUTH, SAFE_AUTH)
  joinType: '',                         // 로그인 타입 (kakao, facebook, apple, email)
  email: '',                            // 이메일
  name: '',                             // 이름
  nickname: '',                         // 닉네임
  phone: '',                            // 휴대전화
  tel: '',                              // 전화번호

  profileUrl: '',                       // 프로필 이미지 url
  profileReset: new Date().valueOf(),   // 프로필 이미지 캐시방지 값

  hasPassword: true,                    // 비밀번호 존재여부
  existRoom: false,                     // 나의 매물 존재여부
  hasFavoriteRoomFilters: false,        // 즐겨찾기 존재 여부

  /** 본인인증 갱신 팝업 check: 갱신팝업 | close: 닫기 | cert: 본인인증  */
  isCertRenewalPopup: 'close',
  /** 본인인증 갱신 여부 */
  isPhoneNumberCheck: false,
};
