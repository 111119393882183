/**
 * @param callback callback function
 * @param timeout millisecond
 */
class Timer {
  private timerId: number | undefined;
  private start: number = 0;

  private remaining;

  private readonly callback: () => void;

  constructor(callback: () => void, timeout: number) {
    this.remaining = timeout;
    this.callback = callback;
  }

  clear() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  pause() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
      this.remaining -= Date.now() - this.start;
    }
  }

  resume() {
    this.start = Date.now();
    clearTimeout(this.timerId);
    this.timerId = window.setTimeout(this.callback, this.remaining);
  }
}

export default Timer;
