import { StateCreator } from 'zustand';

export const initOnetwoFilter: StoreFilter.OneTwoFilter = {
  sellingTypeList: new Set(['MONTHLY_RENT', 'LEASE']),
  depositRange: {
    min: 0,
    max: 999_999,
  },
  priceRange: {
    min: 0,
    max: 999_999,
  },
  isIncludeMaintenance: false,
  pyeongRange: {
    min: 10,
    max: 70,
  },
  useApprovalDateRange: {
    min: 0,
    max: 999_999,
  },
  roomFloorList: new Set(['GROUND_FIRST', 'GROUND_SECOND_OVER', 'SEMI_BASEMENT', 'ROOFTOP']),
  roomTypeList: new Set(['ONE_ROOM', 'TWO_ROOM']),
  dealTypeList: new Set(['AGENT', 'DIRECT']),
  canParking: false,
  isShortLease: false,
  hasElevator: false,
  hasPano: false,
  isDivision: false,
  isDuplex: false,
};

export const createOnetwoFilterSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreFilter.OneTwoFilter & StoreFilter.Actions<StoreFilter.OneTwoFilter>
> = (set, get) => ({
  ...initOnetwoFilter,
  getFilterJson() {
    const filter = get().filter.onetwo;

    return {
      sellingTypeList: [...filter.sellingTypeList],
      depositRange: filter.depositRange,
      priceRange: filter.priceRange,
      isIncludeMaintenance: filter.isIncludeMaintenance,
      pyeongRange: filter.pyeongRange,
      useApprovalDateRange: filter.useApprovalDateRange,
      roomFloorList: [...filter.roomFloorList],
      roomTypeList: [...filter.roomTypeList],
      dealTypeList: [...filter.dealTypeList],
      canParking: filter.canParking,
      isShortLease: filter.isShortLease,
      hasElevator: filter.hasElevator,
      hasPano: filter.hasPano,
      isDivision: filter.isDivision,
      isDuplex: filter.isDuplex,
    };
  },
  resetTarget: target => {
    set(draft => {
      draft.filter.onetwo = {
        ...draft.filter.onetwo,
        [target]: initOnetwoFilter[target],
      };
    });
  },
  reset: () => {
    set(draft => {
      draft.filter.onetwo = {
        ...draft.filter.onetwo,
        ...initOnetwoFilter,
      };

      if (draft.isListReady !== 'drawing') {
        draft.isListReady = '';
      }
    });
  }
});
