import { StateCreator } from 'zustand';

export const initSearch: StoreSearch.Search = {
  id: '',
  type: '',
};

export const createSearchSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreSearch.State
> = set => ({
  ...initSearch,
  resetSearch() {
    set(draft => {
      draft.openType = false;
      draft.search = {
        ...draft.search,
        ...initSearch,
      };
    });
  },
});
