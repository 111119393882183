import styled from 'styled-components';

export const Container = styled.div`
  padding: 108px 0;
  text-align: center;
  
  > h1 {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.subtitle2};
  }
`;

export const Eyes = styled.p`
  margin-bottom: 2px;
  font-size: 32px;
  line-height: 51px;
`;

export const Desc = styled.p`
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ theme }) => theme.font.body2};
`;
