import React, { useRef } from 'react';
import { useSpring, animated, easings } from '@react-spring/web';
import { nanoid } from 'nanoid';

interface Props {
  isHidden: boolean;
}

function HiddenEyeIconComponent({
  isHidden,
}: Props) {
  const _id = useRef(nanoid());

  const { height } = useSpring({
    height: isHidden ? 20 : 0,
    config: {
      duration: 200,
      easing: easings.easeInQuart,
    }
  });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id={_id.current}>
          <rect width="100%" height="100%" fill="#ffffff"/>
          <animated.rect x="4.8999" y="4.8999" width="1" height={height} rx="0.5" transform="rotate(-45 4.8999 4.8999)" fill="#000000"/>
        </mask>
      </defs>

      <path
        d="M2.47461 11.9925C4.07746 8.46725 7.7399 6.00342 12.0016 6.00342C16.2633 6.00342 19.9257 8.46725 21.5286 11.9925C19.9257 15.5178 16.2633 17.9816 12.0016 17.9816C7.7399 17.9816 4.07746 15.5178 2.47461 11.9925ZM12.0023 15.3185C13.9151 15.3185 15.4658 13.8294 15.4658 11.9925C15.4658 10.1556 13.9151 8.66644 12.0023 8.66644C10.0894 8.66644 8.53876 10.1556 8.53876 11.9925C8.53876 13.8294 10.0894 15.3185 12.0023 15.3185ZM12.0019 14.21C13.2771 14.21 14.3109 13.2173 14.3109 11.9927C14.3109 10.7681 13.2771 9.77532 12.0019 9.77532C10.7266 9.77532 9.69284 10.7681 9.69284 11.9927C9.69284 13.2173 10.7266 14.21 12.0019 14.21Z"
        mask={`url(#${_id.current})`}
        fill="#979797"
      />
      <animated.rect
        x="4.2002"
        y="5.6001"
        width="1"
        height={height} rx="0.5"
        transform="rotate(-45 4.2002 5.6001)"
        fill="#979797"
      />
    </svg>
  );
}

export default HiddenEyeIconComponent;
