import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '~/store';

import useHeader from './useHeader';

import MapSearch from './map-search';
import Menu from './menu';
import Profile from './profile';

import { ReactComponent as Logo } from './images/dabang-new-logo-blue.svg';

import {
  Header,
  LogoLink, MenuWrap, AccountContainer, LoginBtn,
} from './styled';

function HeaderComponent() {
  const {
    clickLogo,
    clickLogin,
    clickJoinPro,
  } = useHeader();

  const location = useLocation();
  const { isAuth } = useSelector(({ user }: RootState) => user);

  const isMap = location.pathname.indexOf('/search') === 0 || location.pathname.indexOf('/map') === 0;

  return (
    <Header>
      <div id="gnb-content">
        <LogoLink href="/" onClick={clickLogo}>
          <Logo width="80" height="32" />
        </LogoLink>

        {isMap && (
          <MapSearch />
        )}

        <MenuWrap>
          <Menu />

          <AccountContainer>
            {isAuth ? (
              <Profile />
            ) : (
              <>
                <LoginBtn onClick={clickLogin}>
                  로그인<span />회원가입
                </LoginBtn>

                <LoginBtn onClick={clickJoinPro}>
                  중개사 가입/광고문의
                </LoginBtn>
              </>
            )}
          </AccountContainer>
        </MenuWrap>
      </div>
    </Header>
  );
}

export default HeaderComponent;
