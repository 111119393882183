

function convertSaleInLots(list: Search.SaleInLots[]): Search.SearchItem[] {
  function getColor(type: Search.TSaleInLotsState): Search.Child {
    switch (type) {
      case 'EXPECTED':
        return {
          name: '분양예정',
          shortName: '분양예정',
          color: '#0E2067',
        };

      case 'MOVE_IN_RECRUIT':
        return {
          name: '입주모집',
          shortName: '입주모집',
          color: '#1CA885',
        };

      case 'ACCEPTING':
        return {
          name: '접수중',
          shortName: '접수중',
          color: '#E20724',
        };

      case 'ACCEPT_COMPLETE':
        return {
          name: '접수마감',
          shortName: '접수마감',
          color: '#222',
        };

      case 'MOVE_IN_EXPECTED':
        return {
          name: '입주예정',
          shortName: '입주예정',
          color: '#326CF9',
        };
    }
  }

  return list.map(item => {
    return {
      type: 'sale-in-lots',
      id: item.saleInLotsId,
      name: item.name,
      fullName: item.fullName,
      location: item.location,
      zoom: item.zoom,
      address: item.saleInLotsAddress,
      child: [getColor(item.saleInLotsState)],
    };
  });
}

export default convertSaleInLots;
