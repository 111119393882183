import styled from 'styled-components';
import { a } from '@react-spring/web';

export const BottomSheetContainer = styled.div<{
  $zIndex: number;
}>`
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ $zIndex }) => $zIndex};
`;

export const BottomSheetContent = styled(a.div)`
  flex: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  position: relative;
  z-index: 1002;
`;
