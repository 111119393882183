import { phoneFormat } from '../format';
import isEmpty from './is-empty';

/**
 * @description 유효성 검사 연락처편
 * https://station-3.atlassian.net/wiki/spaces/PDEV/pages/821002817
 */
export default function checkTel(tel: string = ''): boolean | string {
  if (isEmpty(tel)) return '연락처를 입력해주세요.';

  const t = phoneFormat(tel);

  if(!/^(02|03[1|2|3]|04[1|2|3|4]|05[1|2|3|4|5]|06[1|2|3|4]|070|01[0|1|6|7|8|9])[- ]?([0-9]{3,4})[- ]?([0-9]{4})$/.test(t)) {
    return '연락처 형식을 확인해주세요.';
  }

  return false;
}
