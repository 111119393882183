import styled from 'styled-components';

import arrowDown500 from './images/ic_24_arrow_down_gray500.svg';

export const Select = styled.select<{
  contentWidth: string;
  isError: boolean;
}>`
  flex: none;
  width: ${({ contentWidth }) => contentWidth};
  height: 44px;
  padding: 0 40px 0 16px;
  color: ${({ theme, value }) => value !== '' ? theme.color.gray[900] : theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  font-weight: 400;
  border: 1px solid ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[300]};
  border-radius: 2px;
  background-image: url(${arrowDown500});
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.white};
  background-size: 24px 24px;
  transition: all 150ms ease-out;
  appearance: none;
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-color: ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[900]};
    }
  }
    
  &:disabled {
    color: ${({ theme }) => theme.color.gray[500]};
    border-color: ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.gray[50]};
  }
`;
