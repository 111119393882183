import React, { ReactNode } from 'react';

import type { TTitleSize } from '../../types';

import { TitleWrap, Title, Star } from './styled';

interface Props {
  title?: ReactNode | ReactNode[] | string;
  titleSize: TTitleSize;
  hasFakeTitle: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
}

function TitleComponent({
  title = '',
  titleSize,
  hasFakeTitle,
  isRequired = false,
  isDisabled = false,
}: Props) {
  return (
    <TitleWrap titleSize={titleSize} hasFakeTitle={hasFakeTitle}>
      {typeof title === 'string' ? (
        <Title titleSize={titleSize} isDisabled={isDisabled}>
          {title}
          {isRequired && <Star>*</Star>}
        </Title>
      ) : title}
    </TitleWrap>
  );
}

export default TitleComponent;
