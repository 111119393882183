import { StateCreator } from 'zustand';

import { createOnetwoFilterSlice } from './onetwo';
import { createHouseFilterSlice } from './house';
import { createOfficetelFilterSlice } from './officetel';
import { createAptFilterSlice } from './apt';
import { createSaleInLotsFilterSlice } from './sale-in-lots';

/**
 * 필터 데이터는 항상 flat 해야한다.
 * 항상 query-string에 들어가는 부분을 고려해야한다.
 *
 * 필터의 값으로는 key : value 로만 이루어 져야하며
 * value는 Object{} 가 들어가면 안된다.
 *
 * 가능한 value :
 * string, number, Array, Set, boolean
 *
 * Array에 들어갈 수 있는 값은 number, string, boolean 이며
 * 혼용해서 사용하지 않는다.
 */
export const createFilterSlice: StateCreator<
  StoreMap.State,
  [['zustand/immer', unknown]],
  [],
  StoreFilter.State
> = (...a) => ({
  onetwo: createOnetwoFilterSlice(...a),
  house: createHouseFilterSlice(...a),
  officetel: createOfficetelFilterSlice(...a),
  apt: createAptFilterSlice(...a),
  saleInLots: createSaleInLotsFilterSlice(...a),
});

