import React from 'react';

import { PageWrap, Svg } from './styled';

function LoadingComponent() {
  return (
    <PageWrap>
      <Svg width="30" height="30" viewBox="0 0 40 40">
        <polygon points="0 0 0 40 40 40 40 0" />
      </Svg>
    </PageWrap>
  );
}

export default LoadingComponent;
