import styled from 'styled-components';

import { layout } from '~/components/styled/layout';

import arrowDown from '~/components/svg-icon/images/ic-24-arrow-down-gray-500.svg';
import arrowUp from '~/components/svg-icon/images/ic-24-arrow-up-gray-900.svg';

export const Footer = styled.footer`
  ${layout};
  background-color: #373737;
`;

export const TopWrap = styled.div`
  display: flex;
  height: 70px;
  border-bottom: 1px solid #454545;
`;

export const TermsWrap = styled.nav`
  flex-grow: 1;
  height: 100%;
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  > li {
    display: flex;
    align-items: center;
    height: 12px;

    & + li {
      margin-left: 16px;
      padding-left: 16px;
      border-left: 1px solid #666;
    }
  }
`;

export const TermLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})<{ highlight?: boolean; }>`
  color: ${({ highlight }) => (highlight ? '#fff' : '#aaa')};
  font-size: 13px;
  font-weight: ${({ highlight }) => (highlight ? 700 : 400)};
`;

export const SearchForm = styled.form`
  flex: none;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  border-left: 1px solid #454545;
  border-right: 1px solid #454545;

  > label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const SearchText = styled.p`
  flex: none;
  margin-right: 16px;
  color: #909090;
  font-size: 13px;
  line-height: 24px;
  white-space: nowrap;
`;

export const SearchInput = styled.input.attrs({
  type: 'text',
})`
  flex-grow: 1;
  height: 24px;
  margin-right: 10px;
  padding: 0;
  color: #eee;
  font-size: 13px;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const SearchBtn = styled.button`
  flex: none;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 0;
  border: 0;
  background-color: transparent;
  outline: none;

  > svg {
    position: relative;
  }
`;

export const GoTop = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70px;
  height: 100%;
  color: #d2d6da;
  border: 0;
  border-right: 1px solid #454545;
  background-color: transparent;
  outline: none;

  > svg {
    margin-bottom: 6px;
    position: relative;
  }

  > p {
    font-size: 11px;
    line-height: 13px;
    position: relative;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0 37px;
`;

export const InfoWrap = styled.div`
  flex-grow: 1;
`;

export const Info = styled.ul`
  > li {
    height: 18px;
    color: #a0a0a0;
    font-size: 12px;
    line-height: 18px;
    white-space: pre-wrap;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  margin-top: 9px;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
    height: 30px;
    color: #e5e5e5;
    font-size: 12px;
    border-radius: 2px;
    background-color: #515151;
    
    > svg {
      flex: none;
      margin-left: 4px;
    }

    & + a {
      margin-left: 8px;
    }
  }
`;

export const CopyRight = styled.p`
  margin-top: 20px;
  color: #a0a0a0;
  font-size: 12px;
  line-height: 18px;
`;

export const RightWrap = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
`;

export const SocialWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const SocialIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})<{ imgUrl: string; }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ imgUrl }) => `url(${imgUrl})`} center center no-repeat;
  background-size: cover;

  & + a {
    margin-left: 10px;
  }
`;
