function setPrefixYear(genderCode: string) {
  let prefixYear = '';

  switch(genderCode) {
    case '1': // 1900 국내 남성
    case '2': // 1900 국내 여성
    case '5': // 1900 외국 남성
    case '6': // 1900 외국 남성
      prefixYear = '19';
      break;
    case '3': // 2000 국내 남성
    case '4': // 2000 국내 여성
    case '7': // 2000 외국 남성
    case '8': // 2000 외국 남성
      prefixYear = '20';
      break;
    case '0':
    case '9':
      prefixYear = '18';
      break;
    default:
      prefixYear = '';
      break;
  }

  return prefixYear;
}

export default setPrefixYear;
