import { createModel } from '@rematch/core';

import { RootModel } from '..';

import state from './state';

const user = createModel<RootModel>()({
  state,
  reducers: {
    update(state, payload: Partial<typeof state>) {
      return {
        ...state,
        ...payload,
      };
    },
    reset() {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  effects: dispatch => {
    const { user } = dispatch;

    return {
      async setUser(payload: Partial<typeof state>, rootState) {
        user.update(payload);
      },

      checkUrl(needReload: boolean) {
        // 로그인이 필요한 페이지에서 로그아웃하면 리다이렉션 시킨다.
        switch (window.location.pathname) {
          case '/manage':
          case '/user/sms':
          case '/user/call':
          case '/user/inquiry':
          case '/user/account':
          case '/favorite/bookmark':
          case '/favorite/preempt-room':
          case '/favorite/preempt-complex':
            window.location.href = '/';
            break;

          default:
            if (needReload) window.location.reload();
            break;
        }
      },
    };
  },
});

export default user;
