import React, { memo, useMemo } from 'react';

import type { YearRange, MinMax } from '../../useCalendar';

import { YearGrid, YearBtn } from './styled';

interface Props {
  year: number;
  range: YearRange;
  minMax: MinMax;
  onClick(year: number): void;
}

function YearComponent({
  year,
  range,
  minMax,
  onClick,
}: Props) {
  const { startYear, count } = range;
  const years = useMemo(() => Array.from({ length: count }, (_, k) => startYear + k), [count, startYear]);

  return (
    <YearGrid>
      {years.map((y, key) => {
        const isOutOfRange = y > minMax.maxYear || y < minMax.minYear;

        return (
          <li key={key}>
            <YearBtn
              isActive={year === y}
              onClick={onClick.bind(null, y)}
              disabled={isOutOfRange}
            >
              {y}
            </YearBtn>
          </li>
        );
      })}
    </YearGrid>
  );
}

export default memo(YearComponent);
