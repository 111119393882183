import React, { forwardRef, ChangeEvent } from 'react';

import { BaseInputUIComponent } from './base';
import type { TextInputProps } from './types';

export const TextInputUIComponent = forwardRef<HTMLInputElement, TextInputProps>(({
  max,
  value,
  onChange,
  onChangeNative,
  ...props
}, ref) => {
  function change(e: ChangeEvent<HTMLInputElement>) {
    const { name, value: tValue } = e.currentTarget;
    let targetValue = tValue;

    if (value !== undefined && max && targetValue.length > max) return;

    onChangeNative?.(e);
    onChange?.({ name, value: targetValue });
  }

  return (
    <BaseInputUIComponent
      ref={ref}
      max={max}
      type="text"
      value={value}
      onChange={change}
      {...props}
    />
  );
});

TextInputUIComponent.displayName = 'TextInput';
