import size from 'lodash/size';
import rawType from '~/utils/raw-type';

function isString(str: any): str is string {
  return rawType(str) === 'String';
}

function convertPrice(price: number | string = 0, needMan: boolean = true): string {
  const priceStr = price.toString();
  const priceSize = size(priceStr);
  let first = '';
  let last = '';
  let result = '';
  let hasUk = false;

  if (isString(price)) hasUk = price.includes('억');

  const uk = hasUk ? '' : '억';

  if (priceSize > 4) {
    first = priceStr.substring(0, priceSize - 4);
    last = priceStr.substring(priceSize - 4, priceSize);
    result = last === '0000' ? `${first}${uk}` : `${first}${uk}${+last}${needMan ? '만' : ''}`;

  } else {
    result = `${priceStr}${needMan ? '만' : ''}`;
  }

  return result;
}

export default convertPrice;
