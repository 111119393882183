import React from 'react';
import { TextWrap } from './styled';

interface Props {
  msg: string;
  isError: boolean;
}

function MessageComponent({
  msg, isError,
}: Props) {
  return (
    <TextWrap isError={isError}>
      <p>{msg}</p>
    </TextWrap>
  );
}

export default MessageComponent;
