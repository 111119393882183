import React from 'react';

import type { ChipProps } from '../types';

import { SquareChip, DeleteBtn } from './styled';

export function SquareChipUIComponent({
  contentWidth = 'auto',
  type,
  color,
  onClickDelete,
  children,
  style,
}: ChipProps) {
  return (
    <SquareChip
      chipType={type}
      chipColor={color}
      contentWidth={contentWidth}
      style={style}
    >
      <span>{children}</span>

      {onClickDelete && (
        <DeleteBtn onClick={onClickDelete}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.46355 3.75643C4.26829 3.56117 3.95171 3.56117 3.75645 3.75643C3.56118 3.95169 3.56119 4.26828 3.75645 4.46354L7.29198 7.99907L3.75645 11.5346C3.56119 11.7299 3.56119 12.0465 3.75645 12.2417C3.95171 12.437 4.26829 12.437 4.46355 12.2417L7.99909 8.70618L11.5346 12.2417C11.7299 12.437 12.0465 12.437 12.2417 12.2417C12.437 12.0465 12.437 11.7299 12.2417 11.5346L8.70619 7.99907L12.2417 4.46354C12.437 4.26828 12.437 3.95169 12.2417 3.75643C12.0465 3.56117 11.7299 3.56117 11.5346 3.75643L7.99909 7.29197L4.46355 3.75643Z"
              fill="white"
            />
          </svg>
        </DeleteBtn>
      )}
    </SquareChip>
  );
}

SquareChipUIComponent.displayName = 'SquareChip';