import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initMarker: StoreMarker.MarkerState = {
  stateList: [],
  cityList: [],
  dongList: [],
  gridList: [],

  complexList: [],
  saleInLotsList: [],

  subwayList: [],
  univList: [],

  // 중개사무소
  agentList: [],

  // 리스트에서 마우스 오버중인 아이템의 좌표
  hoverListItemLocation: undefined,
  // 마커 아래 정보 (어떤 정보 보여줄지에 대한 값)
  additionalInfo: '',
  // function btn 에서 선택한 추가로 노출할 마커 값
  additionalMarker: '',
  isHideMarker: false,
};

export const useMarkerStore = create<StoreMarker.State>()(
  immer(set => ({
    ...initMarker,
    resetMarkerOnly() {
      set(draft => {
        draft.stateList = [];
        draft.cityList = [];
        draft.dongList = [];
        draft.gridList = [];

        draft.complexList = [];
        draft.saleInLotsList = [];

        draft.subwayList = [];
        draft.univList = [];

        draft.agentList = [];

        draft.hoverListItemLocation = undefined;
      });
    },
    resetMarker() {
      set(initMarker);
    },
  })),
);
