import React from 'react';

import { Container, Eyes, Desc } from './styled';

function EmptyComponent() {
  return (
    <Container>
      <Eyes>
        <span
          role="img"
          aria-label="eyes"
        >
          👀
        </span>
      </Eyes>
      <h1>원하는 검색 결과가 없나요?</h1>
      <Desc>동, 대학교, 지하철, 단지</Desc>
      <Desc>이름에 공백이 없도록 해주세요.</Desc>
      <Desc>시와 동 사이에는 띄어쓰기를 해주세요.</Desc>
    </Container>
  );
}

export default EmptyComponent;
