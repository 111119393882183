function convertSubway(list: Search.Subway[]): Search.SearchItem[] {
  return list.map(item => {
    return {
      type: 'subway',
      id: item.subwayId,
      name: item.name,
      fullName: item.fullName,
      location: item.location,
      zoom: item.zoom,
      child: item.subways.map(subway => {
        return {
          name: subway.fullName,
          shortName: subway.shortName,
          color: subway.color,
        };
      }),
    };
  });
}

export default convertSubway;
