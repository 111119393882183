import styled from 'styled-components';
import { a } from '@react-spring/web';

export const MessageWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Message = styled(a.div)`
  padding: 4px 12px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  background-color: rgba(34, 34, 34, 0.85);
  border-radius: 4px;
`;
