import isEmpty from '~/utils/valid/is-empty';

/**
 * @description 유효성 검사 이름편
 * https://station-3.atlassian.net/wiki/spaces/PDEV/pages/821002817
 */
function checkName(val: string) {
  if (isEmpty(val)) return '이름을 입력해주세요.';

  return false;
}

export default checkName;
