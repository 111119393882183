import { init, RematchRootState, RematchDispatch, DevtoolOptions } from '@rematch/core';
import { models, RootModel } from '~/models';

const devtoolOptions: DevtoolOptions = {};

if (process.env.NODE_ENV === 'development') {
  devtoolOptions.trace = true;
  devtoolOptions.disabled = false;
  
} else {
  devtoolOptions.disabled = true;
}

const store = init({
  models,
  redux: {
    devtoolOptions,
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

export default store;
