import React from 'react';
import type { LabelGroupProps } from '../types';

import Title from '../components/title';
import Message from '../components/message';

import { Container, Content } from './styled';

export function LabelGroupUIComponent({
  contentWidth = 'auto',
  title,
  titleSize = 'base',
  hasFakeTitle = false,
  topSpace = 20,
  leftSpace = 16,
  msg,
  isError = false,
  isHorizontal = false,
  isRequired = false,
  isDisabled = false,
  children,
  style,
}: LabelGroupProps) {
  return (
    <Container
      contentWidth={contentWidth}
      style={style}
    >
      {(hasFakeTitle || title) && (
        <Title
          title={title}
          titleSize={titleSize}
          hasFakeTitle={hasFakeTitle}
          isRequired={isRequired}
          isDisabled={isDisabled}
        />
      )}

      <Content
        hasTitle={!!title || hasFakeTitle}
        hasMsg={!!msg}
        topSpace={topSpace}
        leftSpace={leftSpace}
        isHorizontal={isHorizontal}
      >
        <div>{children}</div>
      </Content>

      {msg && <Message msg={msg} isError={isError} />}
    </Container>
  );
}

LabelGroupUIComponent.displayName = 'LabelGroup';
