import React, { memo, useMemo } from 'react';

import type { MinMax } from '../../useCalendar';

import { MonthGrid, MonthBtn } from './styled';

interface Props {
  year: number;
  month: number;
  minMax: MinMax;
  onClick(month: number): void;
}

function MonthComponent({
  year,
  month,
  minMax,
  onClick,
}: Props) {
  const months = useMemo(() => Array.from({ length: 12 }, (_, m) => m), []);

  return (
    <MonthGrid>
      {months.map((m, key) => {
        const isOutOfRange = minMax.maxYear === minMax.minYear ? (
          m > minMax.maxMonth || m < minMax.minMonth
        ) : year === minMax.maxYear ? (
          m > minMax.maxMonth
        ) : year === minMax.minYear ? (
          m < minMax.minMonth
        ) : false;

        return (
          <li key={key}>
            <MonthBtn
              isActive={month === m + 1}
              onClick={onClick.bind(null, m)}
              disabled={isOutOfRange}
            >
              {m + 1}월
            </MonthBtn>
          </li>
        );
      })}
    </MonthGrid>
  );
}

export default memo(MonthComponent);
