import React, { forwardRef, useState, ChangeEvent } from 'react';
import { BaseInputUIComponent } from './base';
import type { PasswordInputProps } from './types';

import HiddenEyeIcon from './components/hidden-eye-icon';

export const PasswordInputUIComponent = forwardRef<HTMLInputElement, PasswordInputProps>(({
  max,
  value,
  onChange,
  onChangeNative,
  ...props
}, ref) => {
  const [isShow, setShow] = useState(false);

  function changeShow() {
    setShow(prev => !prev);
  }

  function change(e: ChangeEvent<HTMLInputElement>) {
    const { name, value: tValue } = e.currentTarget;
    let targetValue = tValue;

    if (value !== undefined && max && targetValue.length > max) return;

    onChangeNative?.(e);
    onChange?.({ name, value: targetValue });
  }

  return (
    <>
      <BaseInputUIComponent
        ref={ref}
        max={max}
        hideMax
        rightIcon={<HiddenEyeIcon isHidden={!isShow} />}
        onRightIconClick={changeShow}
        type={isShow ? 'text' : 'password'}
        value={value}
        onChange={change}
        {...props}
      />
    </>
  );
});

PasswordInputUIComponent.displayName = 'PasswordInput';
