import { css } from 'styled-components';

export default css`
  :root,
  *,
  body {
    font-family: "Pretendard Variable", -apple-system, system-ui, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    touch-action: manipulation;
  }

  input, button {
    &:focus,
    &:active {
      outline: none;
    }
  }

  textarea,
  input {
    appearance: none;

    &[type="number"]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    
    &[type="number"]::-webkit-inner-spin-button {
      appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &::-ms-clear{
      display: none;
    }
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 0;
    background-color: #fff;
    cursor: pointer;

    /* 터치 하이라이트 제거 */
    outline: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;

    /* IE 버튼 스타일 때문에 사용 */
    > span {
      position: relative;
    }

    &:disabled {
      opacity: 1;
    }
  }

  input, textarea {
    user-select: text;
  }

  select, input, textarea, a {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: 700;
  }
  
  svg {
    shape-rendering: geometricPrecision;
    
    circle,
    ellipse,
    line,
    path,
    polygon,
    polyline,
    rect {
      shape-rendering: geometricPrecision;
    }
  }
`;
