import React, { forwardRef, ChangeEvent } from 'react';

import type { CheckboxProps } from './types';
import type { TFontName, TFontWeight } from '../types';

import {
  Label, Checkbox, TextContent,
} from './styled';

export const CheckboxUIComponent = forwardRef<HTMLInputElement, CheckboxProps>(({
  font = 'body3.regular',
  paddingTop = '0',
  paddingBottom = '0',
  textPosition = 'right',
  onChange,
  onChangeNative,
  children,
  disabled,
  style,
  ...props
}, ref) => {
  const fontInfo = font?.split('.') as [TFontName, TFontWeight] | undefined;

  function change(e: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.currentTarget;
    onChangeNative?.(e);
    onChange?.({ name, value: checked });
  }

  return (
    <Label
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      style={style}
    >
      {textPosition === 'left' && (
        <TextContent
          textPosition={textPosition}
          fontInfo={fontInfo}
          isDisabled={disabled}
        >
          {children}
        </TextContent>
      )}
      <Checkbox
        ref={ref}
        onChange={change}
        disabled={disabled}
        type="checkbox"
        {...props}
      />
      {textPosition === 'right' && (
        <TextContent
          textPosition={textPosition}
          fontInfo={fontInfo}
          isDisabled={disabled}
        >
          {children}
        </TextContent>
      )}
    </Label>
  );
});

CheckboxUIComponent.displayName = 'Checkbox';
