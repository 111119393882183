interface SaveProps {
  type: 'region';
  id: string;
}

function saveRegion({
  type,
  id,
}: SaveProps) {
  localStorage.setItem('recommend_region', JSON.stringify({
    type,
    id,
  }));
}

interface SaveFilterProps {
  filter: {
    sellingTypeList: StoreFilter.TSellingType[];
    depositRange: StoreFilter.Range;
    priceRange: StoreFilter.Range;
    tradeRange: StoreFilter.Range;
    isIncludeMaintenance: boolean;
  }
}

function saveFilter({
  filter,
}: SaveFilterProps) {
  localStorage.setItem('recommend_filter', JSON.stringify({
    sellingTypeList: filter.sellingTypeList,
    depositRange: filter.depositRange,
    priceRange: filter.priceRange,
    tradeRange: filter.tradeRange,
    isIncludeMaintenance: filter.isIncludeMaintenance,
  }));
}

export default {
  saveRegion,
  saveFilter,
};
