import { css, FlattenSimpleInterpolation } from 'styled-components';
import colorRGB from './colorRGB';

export interface Theme {
  title: colorRGB;
  info: colorRGB;
  contents: colorRGB;
  inactive: colorRGB;
  blue: colorRGB;
  red: colorRGB;
  form: colorRGB;
  bgBlue: colorRGB;
  bgGray: colorRGB;
  white: colorRGB;
  line01: colorRGB;
  line02: colorRGB;
  navy: colorRGB;
  color: ThemeColor;
  font: ThemeFont;
}
export interface ColorType {
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
}
export type TColorTypeName = keyof ColorType;

export interface ThemeColor {
  gray: ColorType;
  yellow: ColorType;
  blue: ColorType;
  red: ColorType;
  green: ColorType;
  violet: ColorType;
  pink: ColorType;
  white: string;
}
export type TThemeColorName = keyof ThemeColor;

const color = {
  gray: {
    50: '#FCFCFC',
    100: '#FAFAFA',
    200: '#F5F5F5',
    300: '#EDEDED',
    400: '#DFDFDF',
    500: '#CCCCCC',
    600: '#979797',
    700: '#656565',
    800: '#434343',
    900: '#222222',
  },
  yellow: {
    50: '#FCF5E3',
    100: '#FCEDC9',
    200: '#FCE7B0',
    300: '#FDE097',
    400: '#FDD164',
    500: '#FFB600',
    600: '#D79900',
    700: '#B37F00',
    800: '#7F5A00',
    900: '#4C3600',
  },
  blue: {
    50: '#EEF8FF',
    100: '#BAE0FF',
    200: '#89CEFF',
    300: '#00A9FF',
    400: '#008AFF',
    500: '#326CF9',
    600: '#1E41D0',
    700: '#152D92',
    800: '#0E2067',
    900: '#0C1A53',
  },
  red: {
    50: '#FFF4F4',
    100: '#FDD8DB',
    200: '#FA9DA4',
    300: '#F87680',
    400: '#F63C4A',
    500: '#E20724',
    600: '#A9051A',
    700: '#A10000',
    800: '#810000',
    900: '#600000',
  },
  green: {
    50: '#F3FAF8',
    100: '#DDF2EC',
    200: '#BBE5DA',
    300: '#76CAB5',
    400: '#5FC1A9',
    500: '#1CA885',
    600: '#16866A',
    700: '#10644F',
    800: '#0B4335',
    900: '#05211A',
  },
  violet: {
    50: '#F5F3FE',
    100: '#E2DEFE',
    200: '#BBB3FE',
    300: '#8B7CFD',
    400: '#6451FD',
    500: '#3E26FD',
    600: '#3817CA',
    700: '#2A1197',
    800: '#1C0B65',
    900: '#14084B',
  },
  pink: {
    50: '#FFF2F6',
    100: '#FFC7D7',
    200: '#FF3478',
    300: '#FF85AC',
    400: '#FF5A8E',
    500: '#FF3478',
    600: '#FE005D',
    700: '#E90059',
    800: '#D70057',
    900: '#C20055',
  },
  white: '#ffffff',
} as ThemeColor;

export interface ThemeFont {
  h1: FlattenSimpleInterpolation;
  h2: FlattenSimpleInterpolation;
  h3: FlattenSimpleInterpolation;
  h4: FlattenSimpleInterpolation;
  subtitle1: FlattenSimpleInterpolation;
  subtitle2: FlattenSimpleInterpolation;
  body1: FlattenSimpleInterpolation;
  body2: FlattenSimpleInterpolation;
  body3: FlattenSimpleInterpolation;
  body3SingleLine: FlattenSimpleInterpolation;
  caption1: FlattenSimpleInterpolation;
  caption1SingleLine: FlattenSimpleInterpolation;
  caption2: FlattenSimpleInterpolation;
  caption2SingleLine: FlattenSimpleInterpolation;
}
export type TThemeFontName = keyof ThemeFont;

const font = {
  h1: css`
    font-size: 46px;
    line-height: 70px;
    letter-spacing: -1px;
  `,
  h2: css`
    font-size: 38px;
    line-height: 60px;
    letter-spacing: -1px;
  `,
  h3: css`
    font-size: 30px;
    line-height: 48px;
    letter-spacing: -0.5px;
  `,
  h4: css`
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.5px;
  `,
  subtitle1: css`
    font-size: 22px;
    line-height: 38px;
    letter-spacing: -0.5px;
  `,
  subtitle2: css`
    font-size: 20px;
    line-height: 32px;
  `,
  body1: css`
    font-size: 18px;
    line-height: 30px;
  `,
  body2: css`
    font-size: 16px;
    line-height: 26px;
  `,
  body3: css`
    font-size: 14px;
    line-height: 24px;
  `,
  body3SingleLine: css`
    font-size: 14px;
    line-height: normal;
    letter-spacing: -0.35px;
  `,
  caption1: css`
    font-size: 12px;
    line-height: 20px;
  `,
  caption1SingleLine: css`
    font-size: 12px;
    line-height: normal;
    letter-spacing: -0.3px;
  `,
  caption2: css`
    font-size: 10px;
    line-height: 16px;
  `,
  caption2SingleLine: css`
    font-size: 10px;
    line-height: normal;
    letter-spacing: -0.25px;
  `,
} as ThemeFont;

const theme = {
  title: new colorRGB(34, 34, 34),                        // #222222
  info: new colorRGB(134, 134, 134),                      // #868686
  contents: new colorRGB(101, 101, 101),                  // #656565
  inactive: new colorRGB(174, 174, 174),                  // #aeaeae
  blue: new colorRGB(50, 108, 249),                       // #326cf9
  red: new colorRGB(244, 98, 91),                         // #f4625b
  form: new colorRGB(76, 76, 76),                         // #4c4c4c
  bgBlue: new colorRGB(239, 245, 255),                    // #eff5ff
  bgGray: new colorRGB(250, 250, 250),                    // #fafafa
  white: new colorRGB(255, 255, 255),                     // #ffffff
  line01: new colorRGB(205, 205, 205),                    // #cdcdcd
  line02: new colorRGB(231, 231, 231),                    // #e7e7e7
  navy: new colorRGB(35, 50, 110),                        // #23326e
  color,
  font,
};

export default theme;
