import ReactGA from 'react-ga4';

/**
 * Google Anlytics
 * @param {String} category
 * @param {String} action
 * @param {String} label
 */
function ga(category: string, action: string, label?: string) {
  ReactGA.event({ category, action, label });
}

export default ga;
