import { isValid } from 'date-fns';

import setPrefixYear from '~/utils/valid/set-prefix-year';

function calcAge(birthDay: Date) {
  // 태어난 날짜의 년월일
  const birth = {
    year: birthDay.getFullYear(),
    month: birthDay.getMonth() + 1,
    day: birthDay.getDate(),
  };

  // 현재 날짜의 년월일
  const current = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const timeOfBirthDay = new Date(`${birth.month}-${birth.day}`);
  const timeOfCurrent = new Date(`${current.month}-${current.day}`);

  // 생일 이전인지
  const isBeforeBirthDay = timeOfCurrent < timeOfBirthDay;
  // 만 나이 계산
  const age = isBeforeBirthDay ? current.year - birth.year - 1 : current.year - birth.year;

  return age;
}

function checkValidateBirth(birth: string, genderCode: string) {
  if (birth.length < 6) return { errorBirth: '주민등록번호를 입력해주세요.' };

  const inputY = birth.substring(0,2);
  const inputM = birth.substring(2,4);
  const inputD = birth.substring(4,6);

  const prefixYear = setPrefixYear(genderCode);
  if (prefixYear === '') return { errorBirth: '', errorGenderNum: '주민등록번호 뒷자리를 입력해주세요.' };

  const year = `${prefixYear}${inputY}`;
  const birthDay = new Date(`${year}-${inputM}-${inputD}`);
  const valid = isValid(birthDay);
  
  if (!valid) return { errorBirth: '주민등록번호를 확인해주세요.', errorGenderNum: '' };
  if (calcAge(birthDay) < 14) return { errorBirth: '다방은 만 14세 이상부터 서비스 이용이 가능합니다.', errorGenderNum: '다방은 만 14세 이상부터 서비스 이용이 가능합니다.' };

  return { errorBirth: '', errorGenderNum: '' };
}

export default checkValidateBirth;
