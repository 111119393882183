import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const searchHistory = localStorage.getItem('search-history');

const initSearchHistory: StoreSearchHistory.State = {
  history: searchHistory ? JSON.parse(searchHistory) : [],
};

const { confirm } = window;

export const useSearchHistoryStore = create<StoreSearchHistory.StoreState>()(
  immer((set, get) => ({
    ...initSearchHistory,

    addHistory(item) {
      const history = get().history;

      let nextHistory = history.filter(h => `${h.type}_${h.id}` !== `${item.type}_${item.id}`);
      nextHistory.unshift(item);

      const maxCount = 20;
      if (nextHistory.length > maxCount) {
        nextHistory = nextHistory.slice(0, maxCount);
      }

      localStorage.setItem('search-history', JSON.stringify(nextHistory));

      set(draft => {
        draft.history = nextHistory;
      });

    },
    removeSingleHistory(id) {
      if (!confirm('검색기록을 삭제하시겠습니까?')) return;

      const history = get().history;

      const nextHistory = history.filter(h => h.id !== id);
      localStorage.setItem('search-history', JSON.stringify(nextHistory));

      set(draft => {
        draft.history = nextHistory;
      });

      const input = document.getElementById('search-input');
      if (input) {
        input.focus();
      }
    },
    removeAllHistory() {
      if (!confirm('모든 검색기록을 삭제하시겠습니까?')) return;

      localStorage.setItem('search-history', JSON.stringify([]));

      set(draft => {
        draft.history = [];
      });

      const input = document.getElementById('search-input');
      if (input) {
        input.focus();
      }
    },
  })),
);
